/** @jsxImportSource @emotion/react */
import { useGetAuth } from "../../Hooks/GetAuth";
import {
  getAuth,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import PrimaryButton, { ButtonType } from "./PrimaryButton";

interface Props {}

function onClick() {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  signInWithPopup(auth, provider);
  console.log("redirecting to google auth");
}

function clickLogOut() {
  const auth = getAuth();

  signOut(auth)
    .then(() => {
      console.log("signed out");
    })
    .catch((error) => {
      console.error("error logging out", error);
    });
}

function AuthButton(props: Props) {
  const user = useGetAuth();

  return user.authed ? (
    <div
      css={{
        width: "100%",
      }}
    >
      <p>{user.username}</p>
      <PrimaryButton buttonType={ButtonType.Primary} onClick={clickLogOut}>
        Sign out
      </PrimaryButton>
    </div>
  ) : (
    <PrimaryButton buttonType={ButtonType.Primary} onClick={onClick}>
      Sign in with Google
    </PrimaryButton>
  );
}

export default AuthButton;
