import { useEffect, useState } from "react";
import { User } from "../Types/Types";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export function useGetAuth(): User {
  const [user, setUser] = useState<User>({id: 'na', username: '', authed: false});

  // auth!
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('user is signed in', user.uid, user.displayName)
        const uid = user.uid;
        const username = user.displayName;
        
        setUser({id: uid, username: username || 'unknown', authed: true});
      } else {
        console.log('user is signed out')
        setUser({id: 'na', username: '', authed: false});
      }
    });

		return () => {}
	}, []);

  return user;
}
