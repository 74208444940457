/** @jsxImportSource @emotion/react */
import { Colour, Gradient } from "../../Constants/Colour";
import TabCircle from "../../Images/TabCircle.svg";

interface Props {
  content: { onTap: () => void; title: string; selected: boolean }[];
}

function button(
  content: { onTap: () => void; title: string; selected: boolean },
  length: number,
) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: Colour.white,
        fontSize: "16px",
        fontWeight: "600",
        textAlign: "center",
        padding: "0 15px",
        minWidth: `${100 / length}%`,
      }}
      onClick={content.onTap}
    >
      {content.title}
      {content.selected && (
        <img
          css={{ width: "5px", height: "5px", marginTop: "4px" }}
          src={TabCircle}
          alt={"white circle which appears beneath selected tab title"}
        />
      )}
    </div>
  );
}

function BottomBar({ content }: Props) {
  if (!content || content.length === 0) {
    return null;
  }

  return (
    <div
      css={{
        width: "100%",
        height: "65px",
        minHeight: "65px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: Gradient.primary,
      }}
    >
      <div
        css={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 19px",
        }}
      >
        {content.map((_, i) => {
          return button(content[i], content.length);
        })}
      </div>
      {/* Space at bottom for the iOS swipe up area */}
    </div>
  );
}

export default BottomBar;
