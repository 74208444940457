/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { Colour, Gradient } from "../../Constants/Colour";

interface Props {
  buttonType: ButtonType,
  children: ReactNode,
  onClick?: () => void,
  disabled?: boolean,
}

export enum ButtonType {
  Primary,
  Secondary
}

interface ButtonProperties {
  backgroundImage: string,
  backgroundColor: string,
  color: string,
}

const buttonConfig: Record<ButtonType, ButtonProperties> = {
  [ButtonType.Primary]: {backgroundImage: Gradient.primary, backgroundColor: '', color: Colour.white},
  [ButtonType.Secondary]: {backgroundImage: '', backgroundColor: Colour.tertiary, color: Colour.white},
}

function PrimaryButton({onClick, disabled, children, buttonType} : Props) {
  const config = buttonConfig[buttonType];

  return <button css={{
    ...config,
    minHeight: '75px',
    width: '100%',
    fontSize: "20px",
    fontWeight: '600',
    borderRadius: "40px",
    border: 0,
  }}
  onClick={onClick}
  disabled={disabled}
  >{children}</button>;
}

export default PrimaryButton;
