/** @jsxImportSource @emotion/react */
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  LabelList,
} from "recharts";
import BottomMenu from "../../Components/BottomBar/BottomMenu";
import Gallery from "../../Components/Gallery/Gallery";
import { useGetAuth } from "../../Hooks/GetAuth";
import { Exercise, Workout, bodyPartsConfig } from "../../Types/Types";
import { useGetAllExercises } from "../../Hooks/GetAllExercises";
import moment from "moment";
import Label, { LabelType } from "../../Components/Labels/Label";
import Spacer, { SpacerSize } from "../../Components/Spacing/Spacer";
import { Colour } from "../../Constants/Colour";

type Props = {
  analyticsPage: AppAnalyticsPageType;
  setAnalyticsPage: (page: AppAnalyticsPageType) => void;
  workouts: Workout[];
};

export enum AppAnalyticsPageType {
  Workouts,
  Exercises,
}

function renderExerciseDetails(exercise: Exercise, workouts: Workout[]) {
  const totalWeightData: { name: string; value: number; uv: string }[] = [];
  const averageWeightData: { name: string; value: number; uv: string }[] = [];
  const mostFrequentWeightData: {
    name: string;
    value: number;
    uv: string;
  }[] = [];

  workouts.some((wo, index) => {
    if (index === 30) {
      return true;
    }

    const ex = wo.exercises.find((ex) => {
      return (
        ex.exercise.name === exercise?.name &&
        ex.exercise.bodyPart === exercise?.bodyPart
      );
    });

    if (ex != null) {
      let totalWeight = 0;
      let totalReps = 0;
      let weights = new Map<number, number>();

      ex.sets.forEach((set) => {
        totalWeight += set.weight * set.reps;
        totalReps += set.reps;

        weights.set(set.weight, (weights.get(set.weight) || 0) + 1);
      });

      const dateString = moment(wo.startDateTime).format("DD/MM");

      totalWeightData.push({ name: dateString, value: totalWeight, uv: "" });
      averageWeightData.push({
        name: dateString,
        value: totalWeight / totalReps,
        uv: "",
      });
      mostFrequentWeightData.push({
        name: dateString,
        value: Array.from(weights).reduce((a, b) => (a[1] > b[1] ? a : b))[0],
        uv: "",
      });

      console.log(totalWeightData);

      return false;
    }
    return false;
  });

  if (totalWeightData.length > 0) {
    totalWeightData[0].uv = `${totalWeightData[0].value}`;
  }

  if (averageWeightData.length > 0) {
    averageWeightData[0].uv = `${averageWeightData[0].value}`;
  }

  if (mostFrequentWeightData.length > 0) {
    mostFrequentWeightData[0].uv = `${mostFrequentWeightData[0].value}`;
  }

  return (
    <div
      css={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Spacer size={SpacerSize.Large} />
        {renderChart(mostFrequentWeightData.reverse(), "Top set weight")}
        <Spacer size={SpacerSize.Large} />
        {renderChart(averageWeightData.reverse(), "Average set weight")}
        <Spacer size={SpacerSize.Large} />
        {renderChart(totalWeightData.reverse(), "Total weight lifted")}
        <Spacer size={SpacerSize.Large} />
      </div>
    </div>
  );
}

function renderChart(
  data: { name: string; value: number; uv: string }[],
  title: string,
) {
  const height = 150;

  let content;
  if (data.length === 0) {
    // no data, render message
    content = "No data available";
  } else {
    content = (
      <ResponsiveContainer width="100%" height={height}>
        <LineChart data={data} margin={{ right: 40, top: 27 }}>
          <Line type="monotone" dataKey="value" stroke="#8884d8">
            <LabelList dataKey="uv" position="top" />
          </Line>
          <XAxis dataKey="name" />
          <YAxis width={40} />
        </LineChart>
      </ResponsiveContainer>
    );
  }

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        backgroundColor: Colour.superLightGrey,
        borderRadius: "20px",
      }}
    >
      <Spacer size={SpacerSize.Small} />
      <Label type={LabelType.Tertiary}>{title}</Label>
      {content}
      <Spacer size={SpacerSize.Small} />
    </div>
  );
}

function AppAnalyticsPage({
  analyticsPage,
  setAnalyticsPage,
  workouts,
}: Props) {
  useGetAuth();
  const allExercises = useGetAllExercises(bodyPartsConfig);

  // state for this component
  // ========================
  let blah;

  switch (analyticsPage) {
    case AppAnalyticsPageType.Workouts:
      blah = (
        <div
          css={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <div
            css={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          ></div>
        </div>
      );
      break;
    case AppAnalyticsPageType.Exercises:
      const galleryContent = allExercises?.map((item) => {
        const exerciseGallery = item.exercises.map((exercise) => {
          return {
            content: renderExerciseDetails(exercise, workouts),
            title: exercise.name,
          };
        });
        return {
          content: <Gallery content={exerciseGallery} />,
          title: item.bodyPart,
        };
      });
      blah = (
        <div
          css={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            css={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              position: "absolute",
              overflow: "scroll",
              width: "100%",
              height: "100%",
            }}
          >
            <Spacer size={SpacerSize.Medium} />
            <div css={{ width: "100%" }}>
              <Label type={LabelType.PageTitle}>Analytics</Label>
            </div>
            <Spacer size={SpacerSize.Small} />
            {galleryContent && galleryContent.length > 0 && (
              <Gallery content={galleryContent} />
            )}
          </div>
        </div>
      );
      break;
  }

  return (
    <div
      css={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        css={{
          width: "100%",
          height: "100%",
          padding: "0 24px 0 24px",
          display: "flex",
        }}
      >
        {blah}
      </div>
      <BottomMenu
        content={[
          {
            onTap: () => {
              setAnalyticsPage(AppAnalyticsPageType.Workouts);
            },
            title: "Workouts",
            selected: analyticsPage === AppAnalyticsPageType.Workouts,
          },
          {
            onTap: () => {
              setAnalyticsPage(AppAnalyticsPageType.Exercises);
            },
            title: "Exercises",
            selected: analyticsPage === AppAnalyticsPageType.Exercises,
          },
        ]}
      />
    </div>
  );
}

export default AppAnalyticsPage;
