/** @jsxImportSource @emotion/react */
import { Colour } from "../../Constants/Colour";
import { useAnimationFrame } from "../../Hooks/RequestAnimationFrame";
import { Exercise, BodyPart } from "../../Types/Types";
import React, { useEffect, useRef, useState } from "react";

interface Props {
  value?: Exercise;
  onChange: (value: number) => void;
  bodyPart?: BodyPart;
  max: number;
}

interface Position {
  x: number;
  y: number;
  startX: number;
  startVector: number;
  ref: React.MutableRefObject<HTMLInputElement | null>;
  num: number;
  done: boolean;
}

function Scroller({ value, onChange, bodyPart, max }: Props) {
  const itemWidth = 60;
  
  const [position, setPosition] = useState<Position[]>([
    {x: 0, y: 0, startX: 0, startVector: 0, ref: useRef(null), num: -2, done: false},
    {x: itemWidth, y: 0, startX: 0, startVector: 0, ref: useRef(null), num: -2, done: false},
    {x: itemWidth*2, y: 0, startX: 0, startVector: 0, ref: useRef(null), num: -2, done: false},
    {x: itemWidth*3, y: 0, startX: 0, startVector: 0, ref: useRef(null), num: -2, done: false},
    {x: itemWidth*4, y: 0, startX: 0, startVector: 0, ref: useRef(null), num: -2, done: false},
  ]);
  const observedDiv: React.MutableRefObject<HTMLInputElement | null> = useRef(null);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);
  const [prevTouchX, setPrevTouchX] = useState<number[]>([0,0,0,0]);
  const [scrollSpeed, setScrollSpeed] = useState<number>(0);
  const scrollLeft = useRef(false);

  // re-center animation vars 
  const [shouldAnimateCenter, setShouldAnimateCenter] = useState<boolean>(false);
  const [vectorToMiddle, setVectorToMiddle] = useState<number>(0);

  // calc middle of component
  const offsetLeft = observedDiv.current?.offsetLeft
  const offsetWidth = observedDiv.current?.offsetWidth
  const halfWidth = offsetLeft && offsetWidth ? offsetLeft + (offsetWidth / 2) - (itemWidth / 2) : 0

  const done = position[0].done;
  const index = position[0].num+2;
  
  useEffect(() => {
    if (done === true) {

      const closest = [...position].sort((a, b) => { 
        return Math.abs(a.x - halfWidth) - Math.abs(b.x - halfWidth)
      })[0]

      const vectorToMiddle = closest.x - halfWidth

      if (vectorToMiddle > 0) {
        // right swipe
        scrollLeft.current = true;
      } else {
        // left swipe
        scrollLeft.current = false;
      }
      setVectorToMiddle(Math.abs(vectorToMiddle) * 2)
      setShouldAnimateCenter(true)
    }

		return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [done]);

  useEffect(() => {
    if (observedDiv.current) {
      const offsetLeft = observedDiv.current?.offsetLeft

      setPosition([
        {...position[0], x: offsetLeft},
        {...position[1], x: offsetLeft + itemWidth},
        {...position[2], x: offsetLeft + itemWidth*2},
        {...position[3], x: offsetLeft + itemWidth*3},
        {...position[4], x: offsetLeft + itemWidth*4},
      ])
    }

		return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  useEffect(() => {
    if (shouldAnimateCenter) {
      onChange(index)
      console.log(index)
    }

		return () => {}
	}, [onChange, index, shouldAnimateCenter]);

  // scroll animation
  useAnimationFrame((deltaTimeMs, progress, deltaEase) => {
    if (!observedDiv.current) {
      return
    }

    const rightBound = observedDiv.current.offsetLeft + observedDiv.current.offsetWidth
    const leftBound = observedDiv.current.offsetLeft

    setPosition(prev => {
          // off the left of screen
          if (prev[1].x - (itemWidth/2) < leftBound) {
            const [first, ...rest] = prev;
            const after = [...rest,first]
            first.x = (rest[rest.length - 1].x + itemWidth)
            first.startVector = (rest[rest.length - 1].startVector + itemWidth)
            
            return after.map(val => { return {...val, num: val.num + 1}})
          } else if (prev[prev.length - 2].x + itemWidth + (itemWidth/2) > rightBound) {
            // remove last and move to front
            var clone = [...prev]
            var last = clone.splice(clone.length - 1, 1)[0]
            const after = [last, ...clone]
            last.x = (clone[0].x - itemWidth)
            last.startVector = (clone[0].startVector - itemWidth)
            
            //setNum(num => num - 1)
            return after.map(val => { return {...val, num: val.num - 1}})
          } else {
            if (prev[4].num <= -2) { // at 0
              if (prev[2].x + deltaEase > halfWidth + (itemWidth/2-1)) { // further than 1/2 item away from 0 (use half because further would change the index by moving element)
                const vectorToEnd = (halfWidth + (itemWidth/2-1)) - prev[2].x 
                setShouldAnimate(false)
                return prev.map(val => { return {...val, done: true, x: val.x + vectorToEnd } })
              } 
            }

            if (prev[4].num >= max-2) { // at 0
              if (prev[2].x - deltaEase < halfWidth - (itemWidth/2-1)) { // further than 1/2 item away from 0 (use half because further would change the index by moving element)
                const vectorToEnd = (halfWidth - (itemWidth/2-1)) - prev[2].x 
                setShouldAnimate(false)
                return prev.map(val => { return {...val, done: true, x: val.x + vectorToEnd } })
              } 
            }
      

            let after = prev.map(val => { return {...val, x: scrollLeft.current === true ? val.x - deltaEase : val.x + deltaEase } })
            if (progress >= 1) {
              after = after.map(val => { return {...val, done: true} })
            }
            return after
          }
    })
  }, 500, shouldAnimate, scrollSpeed); 

  // re-center animation
  useAnimationFrame((deltaTimeMs, progress, deltaEase) => {
    if (!observedDiv.current) {
      return
    }

    const rightBound = observedDiv.current.offsetLeft + observedDiv.current.offsetWidth
    const leftBound = observedDiv.current.offsetLeft
    

    setPosition(prev => {
          // off the left of screen
          if (prev[1].x - (itemWidth/2) < leftBound) {
            const [first, ...rest] = prev;
            const after = [...rest,first]
            first.x = (rest[rest.length - 1].x + itemWidth)
            first.startVector = (rest[rest.length - 1].startVector + itemWidth)
            
            return after.map(val => { return {...val, num: val.num + 1}})
          } else if (prev[prev.length - 2].x + itemWidth + (itemWidth/2) > rightBound) {
            // remove last and move to front
            var clone = [...prev]
            var last = clone.splice(clone.length - 1, 1)[0]
            const after = [last, ...clone]
            last.x = (clone[0].x - itemWidth)
            last.startVector = (clone[0].startVector - itemWidth)
            
            //setNum(num => num - 1)
            return after.map(val => { return {...val, num: val.num - 1}})
          } else {
            return prev.map(val => {
               return {...val, x: scrollLeft.current === true ? val.x - deltaEase : val.x + deltaEase } 
              })
          }
    })
  }, 500, shouldAnimateCenter, vectorToMiddle); 

  const touchStart = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    setShouldAnimate(false)
    setShouldAnimateCenter(false)
    setPosition([
      {startVector: position[0].x - touch.clientX, x: position[0].x, y: position[0].y, startX: touch.clientX, ref: position[0].ref, num: position[0].num, done: false},
      {startVector: position[1].x - touch.clientX, x: position[1].x, y: position[1].y, startX: touch.clientX, ref: position[1].ref, num: position[0].num, done: false},
      {startVector: position[2].x - touch.clientX, x: position[2].x, y: position[2].y, startX: touch.clientX, ref: position[2].ref, num: position[0].num, done: false},
      {startVector: position[3].x - touch.clientX, x: position[3].x, y: position[3].y, startX: touch.clientX, ref: position[3].ref, num: position[0].num, done: false},
      {startVector: position[4].x - touch.clientX, x: position[4].x, y: position[4].y, startX: touch.clientX, ref: position[4].ref, num: position[0].num, done: false},
    ])
    setPrevTouchX(prev => [prev[1], touch.clientX, prev[3], performance.now()])
  }

  const touchMove = (e: React.TouchEvent) => {
    const touch = e.touches[0];

    if (!observedDiv.current) {
      return
    }

    const rightBound = observedDiv.current.offsetLeft + observedDiv.current.offsetWidth
    const leftBound = observedDiv.current.offsetLeft
    
    setPosition(prev => {
      // off the left of screen 
      if (prev[1].x - (itemWidth/2) < leftBound) {
        const [first, ...rest] = prev;
        const after = [...rest,first]
        first.x = (rest[rest.length - 1].x + itemWidth)
        first.startVector = (rest[rest.length - 1].startVector + itemWidth)
        
        return after.map(val => { return {...val, num: val.num + 1}})
      } else if (prev[prev.length - 2].x + itemWidth + (itemWidth/2) > rightBound) {
        // remove last and move to front
        var clone = [...prev]
        var last = clone.splice(clone.length - 1, 1)[0]
        const after = [last, ...clone]
        last.x = (clone[0].x - itemWidth)
        last.startVector = (clone[0].startVector - itemWidth)
        
        //setNum(num => num - 1)
        return after.map(val => { return {...val, num: val.num - 1}})
      } else {
        // increase resistance when moving away from 0
        if (prev[4].num <= -2) { // at 0
          if (touch.clientX + prev[2].startVector > halfWidth + (itemWidth/2-1)) { // further than 1/2 item away from 0 (use half because further would change the index by moving element)
            const vectorToEnd = (halfWidth + (itemWidth/2-1)) - prev[2].x 
            return prev.map(val => { return {...val, x: val.x + vectorToEnd } })
          }
        }

        // increase resistance when moving away from max
        if (prev[4].num >= max-2) {
          if (touch.clientX + prev[2].startVector < halfWidth - (itemWidth/2-1)) {
            const vectorToEnd = (halfWidth - (itemWidth/2-1)) - prev[2].x 
            return prev.map(val => { return {...val, x: val.x + vectorToEnd } })
          }
        }

        return prev.map(val => { return {...val, x: touch.clientX + val.startVector } })
      }
    })
    setPrevTouchX(prev => [prev[1], touch.clientX, prev[3], performance.now()])
  }

  // todo: fix when throwing super fast, i think it's because the index isn't updated before it's moved

  const touchEnd = (e: React.TouchEvent) => {
    const touch = e.changedTouches[0];
    const direction = touch.clientX - prevTouchX[0]

    const time = (prevTouchX[3] - prevTouchX[2]) / 1000 // time in seconds between last two touch movements
    const distance = Math.abs(prevTouchX[0] - touch.clientX) // distance in pixels between last two touch movements
    // pixels per second
    const speed = distance / time

    if (speed > 200) {
      setScrollSpeed(speed < 1000 ? speed : 1000)
      if (direction > 0) {
        // right swipe
        scrollLeft.current = false;
      } else {
        // left swipe
        scrollLeft.current = true;
      }
      setShouldAnimate(true)
    } else {
      const closest = [...position].sort((a, b) => { 
        return Math.abs(a.x - halfWidth) - Math.abs(b.x - halfWidth)
      })[0]

  
      const vectorToMiddle = closest.x - halfWidth
      if (vectorToMiddle > 0) {
        // right swipe
        scrollLeft.current = true;
      } else {
        // left swipe
        scrollLeft.current = false;
      }
      setVectorToMiddle(Math.abs(vectorToMiddle) * 2)
      setShouldAnimateCenter(true)
    }
  }

  // if at end, go to one and take postiion of one minus width
  return (
    <div ref={observedDiv} onTouchStart={touchStart} onTouchMove={touchMove} onTouchEnd={touchEnd} css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      height: '75px',
      width: itemWidth*5,
      touchAction: 'none'
    }}>
      <div style={{
        height: '75px',
        width: itemWidth,
        backgroundColor: Colour.lightGrey,
        borderRadius: "10px",
        opacity: 0.5,
      }}/>
      {
        !observedDiv.current ? undefined :
        position.map((val, index) => {
          const pig = val.num + index;

          const ping = Math.abs(val.x - halfWidth)
          const distanceToMiddle = halfWidth - ping
          let perc = (distanceToMiddle / halfWidth)
          let opacity = perc
          perc = easeInQuad(perc)
          opacity = easeInQuad(opacity)
          if (perc < 0.75) { perc = 0.75 }

          return <div
          ref={val.ref}
          key={index}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            height: '50px',
            width: itemWidth,
            position: 'absolute',
            left: val.x,
            color: 'color',
            transform: `scale(${perc})`,
            opacity: opacity,
            fontSize: pig > 99 ? 28 : 30,
            fontWeight: '600',
          }}>{pig >= 0 && pig <= max && pig}</div>
        })
      }
    </div>
  );
}

function easeInQuad(x: number): number {
  return x * x;
}

export default Scroller;