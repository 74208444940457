import { useCallback } from "react";
import { BodyPart, bodyPartsConfig } from "../../Types/Types";
import ScrollerV from "../ExerciseSelect/ScrollerV";

interface Props {
  onChange: (bodyPart: BodyPart) => void;
}

function BodyPartScroller({ onChange }: Props) {
  const onChangeTransformer = useCallback(
    (name: string) => {
      // transforms the string name of the exercise into the exercise object
      // strings are passed in from the Select component, but we want to output the object for storing in state
      const exercise = bodyPartsConfig.find((i) => i === name);
      exercise && onChange(exercise);
    },
    [onChange],
  );

  return (
    <ScrollerV onChange={onChangeTransformer} exercises={bodyPartsConfig} />
  );
}

export default BodyPartScroller;
