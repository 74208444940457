/** @jsxImportSource @emotion/react */
import firebase from "../../firebase";
import BottomMenu from "../../Components/BottomBar/BottomMenu";
import {
  BodyPart,
  Deletable,
  Exercise,
  User,
  WorkoutPlan,
} from "../../Types/Types";
import { USERS_PATH, WORKOUT_PLANS_PATH } from "../../Constants/DatabasePaths";
import BodyPartScroller from "../../Components/BodyPartSelect/BodyPartScroller";
import ExerciseScroller from "../../Components/ExerciseSelect/ExerciseScroller";
import Label, { LabelType } from "../../Components/Labels/Label";
import Spacer, { SpacerSize } from "../../Components/Spacing/Spacer";
import { useEffect, useRef, useState } from "react";
import { useGetWorkoutPlans } from "../../Hooks/GetWorkoutPlans";
import ActionButton, {
  ActionButtonType,
} from "../../Components/Buttons/ActionButton";
import BottomNav from "../../Components/BottomBar/BottonNav";
import PlusSVG from "../../Images/Plus.svg";
import UpArrow from "../../Images/Union.svg";
import DownArrow from "../../Images/Vector.svg";
import TickSVG from "../../Images/Tick.svg";
import Draggable from "../../Images/Draggable.svg";
import { Colour } from "../../Constants/Colour";
import { Modal } from "antd";
import { useGetAuth } from "../../Hooks/GetAuth";

type Props = {
  creationPage: AppCreationPageType;
  setCreationPage: (page: AppCreationPageType) => void;
  workoutPlanExercises: Exercise[];
  setWorkoutPlanExercises: (exercises: Exercise[]) => void;
};

export enum AppCreationPageType {
  Workouts,
  Exercises,
  CreateWorkouts,
  CreateExercises,
}

function handleDeleteWorkoutPlan(user: User, workoutPlanId?: string) {
  console.log("delete workout plan?", workoutPlanId);
  if (workoutPlanId) {
    Modal.confirm({
      title: "Are you sure you want to delete this plan?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        // Proceed with the deletion if user confirms
        firebase
          .database()
          .ref(USERS_PATH)
          .child(user.id)
          .child(WORKOUT_PLANS_PATH)
          .child(workoutPlanId)
          .remove();
      },
      onCancel() {
        // Optionally handle the cancel, or simply do nothing
      },
    });
  }
}

function AppCreationPage({
  creationPage,
  setCreationPage,
  workoutPlanExercises,
  setWorkoutPlanExercises,
}: Props) {
  const workoutPlans: WorkoutPlan[] = useGetWorkoutPlans();
  const user = useGetAuth();

  // state for this component
  const [exercise, setExercise] = useState<Exercise | undefined>();
  const [bodyPart, setBodyPart] = useState<BodyPart>(BodyPart.Abs);
  const [workoutPlanExpandedState, setWorkoutPlanExpandedState] = useState<
    boolean[]
  >([]);
  const [nameInputValue, setNameInputValue] = useState<string>("");
  const [runHoldTimer, setRunHoldTimer] = useState<boolean>(false);
  const timeoutId = useRef<any>(null);
  const [heldItemId, setHeldItemId] = useState<{
    itemId?: string;
    itemType: Deletable;
    childId?: string;
  }>();
  // ========================

  useEffect(() => {
    // set the expanded state for all exercise cards
    const state =
      workoutPlans.map((_, index) => {
        return index === 0;
      }) || [];

    setWorkoutPlanExpandedState(state);
    return () => {};
  }, [workoutPlans]);

  // hold timer
  useEffect(() => {
    // we've started holding and there isn't a timer running
    if (runHoldTimer && timeoutId.current === null) {
      console.log("start hold");
      timeoutId.current = setTimeout(() => {
        if (runHoldTimer) {
          console.log("still holding");
          if (heldItemId?.itemType === Deletable.WorkoutPlan) {
            handleDeleteWorkoutPlan(user, heldItemId.itemId);
          }
        }
        // clear
        console.log("clearing");
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }, 250);
    }

    return () => {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    };
  }, [runHoldTimer, heldItemId, user]);

  function submitWorkoutPlan() {
    const workoutPlan: WorkoutPlan = {
      exercises: workoutPlanExercises,
      name: nameInputValue,
    };

    console.log("saving workout plan", workoutPlan);
    firebase
      .database()
      .ref(USERS_PATH)
      .child(user.id)
      .child(WORKOUT_PLANS_PATH)
      .push(workoutPlan);
    setWorkoutPlanExercises([]);
  }

  function addExercise() {
    if (exercise) {
      setWorkoutPlanExercises([...workoutPlanExercises, exercise]);
    }
  }

  let blah;

  function renderExercises() {
    return workoutPlanExercises.map((exercise, index) => {
      return (
        <div key={index}>
          <Label type={LabelType.Primary} align={"center"}>
            {exercise.name}
          </Label>
        </div>
      );
    });
  }

  function submitWorkoutPlanDisabled() {
    return workoutPlanExercises.length === 0 || nameInputValue === "";
  }

  function renderPreviousWorkouts() {
    const theWorkouts = workoutPlans.map((workoutPlan, index) => {
      const expanded = workoutPlanExpandedState[index] === true;

      const exercises = workoutPlan.exercises.map((ex, i) => {
        const lastIndex = workoutPlan.exercises.length - 1;
        return (
          <div
            css={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: Colour.white,
              width: "100%",
              height: "37px",
              borderRadius: "10px",
              padding: "20px",
              marginBottom: i === lastIndex ? "0px" : "10px",
            }}
            key={i}
          >
            <img
              src={Draggable}
              alt={"draggable icon"}
              css={{ marginRight: "20px" }}
            />
            <Label type={LabelType.Blackery} align={"center"}>
              {ex.name}
            </Label>
          </div>
        );
      });

      return (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
          key={index}
        >
          {/* workout banner with name */}
          <div
            css={{
              width: "100%",
              backgroundColor: "#F0F0F0",
              height: "38px",
              borderRadius: expanded ? "10px 10px 0 0" : "10px",
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
              padding: "15px",
            }}
            onClick={() => {
              const copy = [...workoutPlanExpandedState];
              copy[index] = !copy[index];
              setWorkoutPlanExpandedState(copy);
            }}
            onTouchStart={() => {
              setRunHoldTimer(true);
              console.log("check this", workoutPlan);
              setHeldItemId({
                itemId: workoutPlan?.id,
                itemType: Deletable.WorkoutPlan,
              });
            }}
            onTouchEnd={() => {
              setRunHoldTimer(false);
              setHeldItemId(undefined);
            }}
            onTouchMove={() => {
              setRunHoldTimer(false);
              setHeldItemId(undefined);
            }}
          >
            <div css={{ width: "55%" }}>
              <Label type={LabelType.Heading} align={"left"}>
                {workoutPlan.name}
              </Label>
            </div>
            <img
              src={expanded ? DownArrow : UpArrow}
              alt={"exercise dropdown arrow"}
            />
          </div>
          {/* expanded exercise details - sets */}
          {expanded && (
            <div
              css={{
                padding: "10px",
                backgroundColor: Colour.superLightGrey,
                width: "100%",
                borderRadius: "0 0 10px 10px",
              }}
            >
              {exercises}
            </div>
          )}
          <Spacer size={SpacerSize.Small} />
        </div>
      );
    });

    const summary = (
      <div
        css={{
          width: "100%",
          height: "100%",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {theWorkouts}
          <Spacer size={SpacerSize.ActionBottom} />
        </div>
      </div>
    );
    return { summary };
  }

  switch (creationPage) {
    case AppCreationPageType.Workouts:
      const previousWorkouts = renderPreviousWorkouts();
      blah = (
        <div
          css={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          <div
            css={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              height: "100%",
              position: "absolute",
              overflow: "scroll",
            }}
          >
            <Spacer size={SpacerSize.Medium} />
            <div css={{ width: "100%" }}>
              <Label type={LabelType.PageTitle}>Workout Plans</Label>
            </div>
            <Spacer size={SpacerSize.Small} />
            {previousWorkouts.summary}
          </div>
          <ActionButton
            buttonType={ActionButtonType.Primary}
            onClick={() => {
              setCreationPage(AppCreationPageType.CreateWorkouts);
            }}
          >
            <img src={PlusSVG} alt={"action button plus icon"} />
          </ActionButton>
        </div>
      );
      break;
    case AppCreationPageType.CreateWorkouts:
      blah = (
        <div
          css={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <div
            css={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <BodyPartScroller onChange={setBodyPart} />
            <Spacer size={SpacerSize.Large} />
            <Label type={LabelType.Primary} align={"center"}>
              Exercise
            </Label>
            <Spacer size={SpacerSize.Small} />
            <ExerciseScroller onChange={setExercise} bodyPart={bodyPart} />
            <input
              type="text"
              placeholder="Name"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setNameInputValue(event.target.value)
              }
              value={nameInputValue}
            />
            {renderExercises()}
          </div>
          <ActionButton
            buttonType={ActionButtonType.Primary}
            onClick={addExercise}
          >
            <img src={PlusSVG} alt={"action button plus icon"} />
          </ActionButton>
          <ActionButton
            buttonType={ActionButtonType.Primary}
            onClick={submitWorkoutPlan}
            disabled={submitWorkoutPlanDisabled()}
          >
            <img src={TickSVG} alt={"action button plus icon"} />
          </ActionButton>
        </div>
      );
      break;
  }

  return (
    <div
      css={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        css={{
          width: "100%",
          height: "100%",
          padding: "0 24px 0 24px",
          display: "flex",
        }}
      >
        {blah}
      </div>
      <BottomNav
        content={
          creationPage === AppCreationPageType.CreateWorkouts
            ? [
                {
                  onTap: () => {
                    setCreationPage(AppCreationPageType.Workouts);
                  },
                  title: "Workouts",
                },
              ]
            : []
        }
      />
      {creationPage !== AppCreationPageType.CreateWorkouts && (
        <BottomMenu
          content={[
            {
              onTap: () => {
                setCreationPage(AppCreationPageType.Workouts);
              },
              title: "Workouts",
              selected: creationPage === AppCreationPageType.Workouts,
            },
            {
              onTap: () => {
                setCreationPage(AppCreationPageType.Exercises);
              },
              title: "Exercises",
              selected: creationPage === AppCreationPageType.Exercises,
            },
          ]}
        />
      )}
    </div>
  );
}

export default AppCreationPage;
