/** @jsxImportSource @emotion/react */
import { Colour } from "../../Constants/Colour";
import { useAnimationFrame } from "../../Hooks/RequestAnimationFrame";
import React, { useEffect, useRef, useState } from "react";

interface Props {
  exercises: string[];
  onChange: (value: string) => void;
}

interface Position {
  x: number;
  y: number;
  startX: number;
  startVector: number;
  ref: React.MutableRefObject<HTMLInputElement | null>;
  num: number;
  done: boolean;
}

function ScrollerV({ exercises, onChange }: Props) {
  const max = exercises.length
  const itemHeight = 40;
  const halfItemHeight = itemHeight / 2
  
  const [position, setPosition] = useState<Position[]>([
    {y: 0, x: 0, startX: 0, startVector: 0, ref: useRef(null), num: -2, done: false},
    {y: itemHeight, x: 0, startX: 0, startVector: 0, ref: useRef(null), num: -2, done: false},
    {y: itemHeight*2, x: 0, startX: 0, startVector: 0, ref: useRef(null), num: -2, done: false},
    {y: itemHeight*3, x: 0, startX: 0, startVector: 0, ref: useRef(null), num: -2, done: false},
    {y: itemHeight*4, x: 0, startX: 0, startVector: 0, ref: useRef(null), num: -2, done: false},
  ]);
  const observedDiv: React.MutableRefObject<HTMLInputElement | null> = useRef(null);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);
  const [prevTouchX, setPrevTouchX] = useState<number[]>([0,0,0,0]);
  const [scrollSpeed, setScrollSpeed] = useState<number>(0);
  const scrollLeft = useRef(false);

  // re-center animation vars
  const [shouldAnimateCenter, setShouldAnimateCenter] = useState<boolean>(false);
  const [vectorToMiddle, setVectorToMiddle] = useState<number>(0);

  // calc middle of component
  const offsetTop = observedDiv.current?.offsetTop
  const offsetHeight = observedDiv.current?.offsetHeight
  const halfHeight = offsetTop && offsetHeight ? (offsetTop + (offsetHeight / 2)) : 0

  const done = position[0].done;
  const index = position[0].num+2;
  
  useEffect(() => {
    if (done === true) {
      const closest = [...position].sort((a, b) => { 
        return Math.abs(a.y + halfItemHeight - halfHeight) - Math.abs(b.y + halfItemHeight - halfHeight)
      })[0]

      const vectorToMiddle = closest.y + halfItemHeight - halfHeight

      if (vectorToMiddle > 0) {
        // right swipe
        scrollLeft.current = true;
      } else {
        // left swipe
        scrollLeft.current = false;
      }
      setVectorToMiddle(Math.abs(vectorToMiddle) * 2)
      setShouldAnimateCenter(true)
    }

		return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [done]);

    
  useEffect(() => {
    if (shouldAnimateCenter === true) {
      onChange(exercises[index])
      console.log(exercises[index])
    }

		return () => {}
	}, [onChange, index, exercises, shouldAnimateCenter]);

  useEffect(() => {
    // reset
    if (observedDiv.current) {
      const offsetTop = observedDiv.current?.offsetTop

      setPosition([
        {...position[0], y: offsetTop, num: -2, done: false},
        {...position[1], y: offsetTop + itemHeight, num: -2, done: false},
        {...position[2], y: offsetTop + itemHeight*2, num: -2, done: false},
        {...position[3], y: offsetTop + itemHeight*3, num: -2, done: false},
        {...position[4], y: offsetTop + itemHeight*4, num: -2, done: false},
      ])
    }

    if (exercises) {
      onChange(exercises[0])
      console.log(exercises[0])
      setShouldAnimate(false)
      setShouldAnimateCenter(false)
    }

		return () => {}
  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exercises[0]]);

  // scroll animation
  useAnimationFrame((deltaTimeMs, progress, deltaEase) => {
    if (!observedDiv.current) {
      return
    }

    const topBound = observedDiv.current.offsetTop
    const bottomBound = observedDiv.current.offsetTop + observedDiv.current.offsetHeight

    setPosition(prev => {
          // off the left of screen
          if (prev[1].y - (halfItemHeight) < topBound) {
            const [first, ...rest] = prev;
            const after = [...rest,first]
            first.y = (rest[rest.length - 1].y + itemHeight)
            first.startVector = (rest[rest.length - 1].startVector + itemHeight)
            
            return after.map(val => { return {...val, num: val.num + 1}})
          } else if (prev[prev.length - 2].y + itemHeight + (itemHeight/2) > bottomBound) {
            // remove last and move to front
            var clone = [...prev]
            var last = clone.splice(clone.length - 1, 1)[0]
            const after = [last, ...clone]
            last.y = (clone[0].y - itemHeight)
            last.startVector = (clone[0].startVector - itemHeight)
            
            //setNum(num => num - 1)
            return after.map(val => { return {...val, num: val.num - 1}})
          } else {
            if (prev[4].num <= -2) { // at 0
              if (prev[2].y + halfItemHeight + deltaEase > halfHeight + (halfItemHeight-1)) { // further than 1/2 item away from 0 (use half because further would change the index by moving element)
                const vectorToEnd = (halfHeight + (halfItemHeight-1)) - (prev[2].y + halfItemHeight) // get furthest position (clamp)
                setShouldAnimate(false)
                return prev.map(val => { return {...val, done: true, y: val.y + vectorToEnd } })
              } 
            }

            if (prev[4].num >= max-3) { // at 0
              if (prev[2].y + halfItemHeight - deltaEase < halfHeight - (halfItemHeight-1)) { // further than 1/2 item away from 0 (use half because further would change the index by moving element)
                const vectorToEnd = (halfHeight - (halfItemHeight-1)) - (prev[2].y + halfItemHeight) // get furthest position (clamp)
                setShouldAnimate(false)
                return prev.map(val => { return {...val, done: true, y: val.y + vectorToEnd } })
              } 
            }

            let after = prev.map(val => { return {...val, y: scrollLeft.current === true ? val.y - deltaEase : val.y + deltaEase } })
            if (progress >= 1) {
              after = after.map(val => { return {...val, done: true} })
            }
            return after
          }
    })
    if (progress >= 1) {
      setShouldAnimate(false)
    }
  }, 500, shouldAnimate, scrollSpeed); 

  // re-center animation
  useAnimationFrame((deltaTimeMs, progress, deltaEase) => {
    if (!observedDiv.current) {
      return
    }

    const bottomBound = observedDiv.current.offsetTop + observedDiv.current.offsetHeight
    const topBound = observedDiv.current.offsetTop
    

    setPosition(prev => {
          // off the left of screen
          if (prev[1].y - halfItemHeight < topBound) {
            const [first, ...rest] = prev;
            const after = [...rest,first]
            first.y = (rest[rest.length - 1].y + itemHeight)
            first.startVector = (rest[rest.length - 1].startVector + itemHeight)
            
            return after.map(val => { return {...val, num: val.num + 1}})
          } else if (prev[prev.length - 2].y + itemHeight + (itemHeight/2) > bottomBound) {
            // remove last and move to front
            var clone = [...prev]
            var last = clone.splice(clone.length - 1, 1)[0]
            const after = [last, ...clone]
            last.y = (clone[0].y - itemHeight)
            last.startVector = (clone[0].startVector - itemHeight)
            
            //setNum(num => num - 1)
            return after.map(val => { return {...val, num: val.num - 1}})
          } else {
            return prev.map(val => { return {...val, y: scrollLeft.current === true ? val.y - deltaEase : val.y + deltaEase } })
          }
    })
    if (progress >= 1) {
      setShouldAnimateCenter(false)
    }
  }, 500, shouldAnimateCenter, vectorToMiddle); 

  const touchStart = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    setShouldAnimate(false)
    setShouldAnimateCenter(false)
    setPosition([
      {startVector: position[0].y - touch.clientY, x: position[0].y, y: position[0].y, startX: touch.clientY, ref: position[0].ref, num: position[0].num, done: false},
      {startVector: position[1].y - touch.clientY, x: position[1].y, y: position[1].y, startX: touch.clientY, ref: position[1].ref, num: position[0].num, done: false},
      {startVector: position[2].y - touch.clientY, x: position[2].y, y: position[2].y, startX: touch.clientY, ref: position[2].ref, num: position[0].num, done: false},
      {startVector: position[3].y - touch.clientY, x: position[3].y, y: position[3].y, startX: touch.clientY, ref: position[3].ref, num: position[0].num, done: false},
      {startVector: position[4].y - touch.clientY, x: position[4].y, y: position[4].y, startX: touch.clientY, ref: position[4].ref, num: position[0].num, done: false},
    ])
    setPrevTouchX(prev => [prev[1], touch.clientY, prev[3], performance.now()])
  }

  const touchMove = (e: React.TouchEvent) => {
    const touch = e.touches[0];

    if (!observedDiv.current) {
      return
    }

    const bottomBound = observedDiv.current.offsetTop + observedDiv.current.offsetHeight
    const topBound = observedDiv.current.offsetTop

    
    setPosition(prev => {
      // off the left of screen
      if (prev[1].y - halfItemHeight < topBound) {
        const [first, ...rest] = prev;
        const after = [...rest,first]
        first.y = (rest[rest.length - 1].y + itemHeight)
        first.startVector = (rest[rest.length - 1].startVector + itemHeight)
        
        return after.map(val => { return {...val, num: val.num + 1}})
      } else if (prev[prev.length - 2].y + itemHeight + (itemHeight/2) > bottomBound) {
        // remove last and move to front
        var clone = [...prev]
        var last = clone.splice(clone.length - 1, 1)[0]
        const after = [last, ...clone]
        last.y = (clone[0].y - itemHeight)
        last.startVector = (clone[0].startVector - itemHeight)
        
        //setNum(num => num - 1)
        return after.map(val => { return {...val, num: val.num - 1}})
      } else {
        // increase resistance when moving away from 0
        if (prev[4].num <= -2) { // at 0
          if (touch.clientY + prev[2].startVector + halfItemHeight > halfHeight + (halfItemHeight-1)) { // further than 1/2 item away from 0 (use half because further would change the index by moving element)
            const vectorToEnd = (halfHeight + (halfItemHeight-1)) - (prev[2].y + halfItemHeight)
            return prev.map(val => { return {...val, y: val.y + vectorToEnd } })
          }
        }

        // increase resistance when moving away from max
        if (prev[4].num >= max-3) { // at 0
          if (touch.clientY + prev[2].startVector + halfItemHeight < halfHeight - (halfItemHeight-1)) { // further than 1/2 item away from 0 (use half because further would change the index by moving element)
            const vectorToEnd = (halfHeight - (halfItemHeight-1)) - (prev[2].y + halfItemHeight)
            return prev.map(val => { return {...val, y: val.y + vectorToEnd } })
          }
        }
        
        return prev.map(val => { return {...val, y: touch.clientY + val.startVector } })
      }
    })
    setPrevTouchX(prev => [prev[1], touch.clientY, prev[3], performance.now()])
  }

  // todo: normalise speed to use perc of screen size rather than pixels

  const touchEnd = (e: React.TouchEvent) => {
    const touch = e.changedTouches[0];
    const direction = touch.clientY - prevTouchX[0]

    const time = (prevTouchX[3] - prevTouchX[2]) / 1000 // time in seconds between last two touch movements
    const distance = Math.abs(prevTouchX[0] - touch.clientY) // distance in pixels between last two touch movements
    // pixels per second
    const speed = distance / time

    if (speed > 200) {
      setScrollSpeed(speed < 1000 ? speed : 1000)
      if (direction > 0) {
        // right swipe
        scrollLeft.current = false;
      } else {
        // left swipe
        scrollLeft.current = true;
      }
      setShouldAnimate(true)
    } else {
      const closest = [...position].sort((a, b) => { 
        return Math.abs(a.y + halfItemHeight - halfHeight) - Math.abs(b.y + halfItemHeight - halfHeight)
      })[0]

  
      const vectorToMiddle = closest.y + halfItemHeight - halfHeight
      if (vectorToMiddle > 0) {
        // right swipe
        scrollLeft.current = true;
      } else {
        // left swipe
        scrollLeft.current = false;
      }
      setVectorToMiddle(Math.abs(vectorToMiddle) * 2)
      setShouldAnimateCenter(true)
    }
  }

  // if at end, go to one and take postiion of one minus width
  return (
    <div ref={observedDiv} onTouchStart={touchStart} onTouchMove={touchMove} onTouchEnd={touchEnd} css={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      height: itemHeight*5,
      width: '100%',
      touchAction: 'none'
    }}>
      <div style={{
            height: itemHeight,
            width: '100%',
            backgroundColor: Colour.lightGrey,
            borderRadius: "10px",
            opacity: 0.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
      }}>{exercises.length === 0 && 'No exercises found'}</div>
      {
        !observedDiv.current ? undefined :
        position.map((val, index) => {
          const pig = val.num + index;

          const top = offsetTop || 0
          const distanceToMiddle = Math.abs((val.y + (halfItemHeight)) - halfHeight)
          const ping = (halfHeight - top) - distanceToMiddle
          let perc = (ping / (halfHeight - top))
          let opacity = perc
          perc = easeInQuad(perc)
          opacity = easeInQuad(opacity)
          if (perc < 0.75) { perc = 0.75 }

          return <div
          ref={val.ref}
          key={index}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: itemHeight,
            position: 'absolute',
            textAlign: 'center',
            top: val.y,
            color: 'black',
            transform: `scale(${perc})`,
            opacity: opacity,
            fontSize: 20,
            fontWeight: '600',
          }}>{pig > -1 && exercises[pig]}</div>
        })
      }
    </div>
  );
}

function easeInQuad(x: number): number {
  return x * x;
}

export default ScrollerV;