import { useEffect, useState } from "react";
import { BodyPart, Exercise } from "../Types/Types";
import firebase from "../firebase";
import { EXERCISES_PATH, USERS_PATH } from "../Constants/DatabasePaths";
import { useGetAuth } from "./GetAuth";

// Optional parameter bodyPart
// When passed, filter the exercises returned to only be that of the bodyPart
export function useGetExercises(bodyPart?: BodyPart): Exercise[] {
  const user = useGetAuth();
  const [exercises, setExercises] = useState<Exercise[]>([]);

  useEffect(() => {
    var exercisesRef = firebase
      .database()
      .ref(USERS_PATH)
      .child(user.id)
      .child(EXERCISES_PATH);

    exercisesRef.on("value", (snapshot) => {
      const data: any = snapshot.val();

      const items: Exercise[] = [];
      for (let item in data) {
        if (bodyPart) {
          // filter out exercises that are not for the body part
          if (data[item].bodyPart === bodyPart) {
            items.push(data[item]);
          }
        } else {
          // no body part specified, so return all exercises
          items.push(data[item]);
        }
      }
      setExercises(items);
    });

    return () => {
      exercisesRef.off();
    };
  }, [bodyPart, user.id]);

  return exercises;
}
