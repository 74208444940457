export interface Workout {
  id?: string;
  durationMs: number;
  startDateTime: number;
  exercises: WorkoutExercise[];
  workoutPlan?: WorkoutPlan;
}

export interface WorkoutExercise {
  id?: string;
  exercise: Exercise;
  sets: Set[];
}

export interface Exercise {
  id?: string;
  bodyPart: BodyPart;
  name: string;
}

export interface WorkoutPlan {
  id?: string;
  exercises: Exercise[];
  name: string;
}

export interface Set {
  id?: string;
  reps: number;
  weight: number;
}

export enum BodyPart {
  Abs = "Abs",
  Back = "Back",
  Biceps = "Biceps",
  Cardio = "Cardio",
  Chest = "Chest",
  Legs = "Legs",
  Shoulders = "Shoulders",
  Triceps = "Triceps",
}

export enum Deletable {
  Exercise,
  Workout,
  Set,
  WorkoutPlan,
}

export interface User {
  id: string;
  username: string;
  authed: boolean;
}

export const bodyPartsConfig = [
  BodyPart.Abs,
  BodyPart.Back,
  BodyPart.Biceps,
  BodyPart.Chest,
  BodyPart.Legs,
  BodyPart.Shoulders,
  BodyPart.Triceps,
];
