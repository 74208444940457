import React, { useState } from "react";
import BodyPartSelect from "../../Components/BodyPartSelect/BodyPartSelect";
import ExerciseItem from "../../Components/ExerciseItem/ExerciseItem";
import firebase from "../../firebase";
import { Input } from "antd";
import { BodyPart, Exercise } from "../../Types/Types";
import { useGetExercises } from "../../Hooks/GetExercises";
import { useGetAuth } from "../../Hooks/GetAuth";
import { USERS_PATH } from "../../Constants/DatabasePaths";

interface Props {}

const EXERCISES_PATH = "exercises/";

function WorkoutsPage(props: Props) {
  const user = useGetAuth();
  const exercises: Exercise[] = useGetExercises();
  const [inputText, setInputText] = useState<string>("");
  const [bodyPart, setBodyPart] = useState<BodyPart>(BodyPart.Abs);

  function submitExercise() {
    const exercise: Exercise = { bodyPart: bodyPart, name: inputText };

    firebase.database().ref(USERS_PATH).child(user.id).child(EXERCISES_PATH).push(exercise);
    setInputText("");
  }

  function updateInput(e: React.ChangeEvent<HTMLInputElement>) {
    setInputText(e.target.value);
  }

  function updateBodyPart(bodyPart: BodyPart) {
    setBodyPart(bodyPart);
  }

  function renderExercises() {
    return exercises.map((exercise) => <ExerciseItem exercise={exercise} />);
  }

  return (
    <div>
      <h1>Workout Library</h1>
      <Input
        onChange={updateInput}
        value={inputText}
        placeholder={"Exercise name"}
      />
      <BodyPartSelect value={bodyPart} onChange={updateBodyPart} />
      <button onClick={submitExercise}>Submit</button>
      <div>
        Exercises:
        {renderExercises()}
      </div>
    </div>
  );
}

export default WorkoutsPage;
