/** @jsxImportSource @emotion/react */

import { ReactNode } from "react";
import { Colour } from "../../Constants/Colour";

interface Props {
  type: LabelType;
  align?: any;
  children?: ReactNode;
}

export enum LabelType {
  PageTitle,
  Primary,
  Secondary,
  Tertiary,
  Fourthery,
  Heading,
  Table,
  Blackery,
  CTA,
  Banner,
}

interface LabelProperties {
  fontSize: string;
  color: string;
  minHeight: string;
  fontWeight: string;
}

const LINE_HEIGHT_MULTI = 1.5715;

const labelConfig: Record<LabelType, LabelProperties> = {
  [LabelType.Primary]: {
    fontSize: "16px",
    color: Colour.primary,
    minHeight: `${16 * LINE_HEIGHT_MULTI}px`,
    fontWeight: "600",
  },
  [LabelType.Secondary]: {
    fontSize: "16px",
    color: Colour.secondary,
    minHeight: `${16 * LINE_HEIGHT_MULTI}px`,
    fontWeight: "600",
  },
  [LabelType.Tertiary]: {
    fontSize: "16px",
    color: Colour.secondaryBlack,
    minHeight: `${16 * LINE_HEIGHT_MULTI}px`,
    fontWeight: "600",
  },
  [LabelType.Fourthery]: {
    fontSize: "16px",
    color: Colour.tertiary,
    minHeight: `${16 * LINE_HEIGHT_MULTI}px`,
    fontWeight: "450",
  },
  [LabelType.Heading]: {
    fontSize: "16px",
    color: Colour.black,
    minHeight: `${16 * LINE_HEIGHT_MULTI}px`,
    fontWeight: "450",
  },
  [LabelType.Table]: {
    fontSize: "16px",
    color: Colour.secondaryBlack,
    minHeight: `${16 * LINE_HEIGHT_MULTI}px`,
    fontWeight: "450",
  },
  [LabelType.Blackery]: {
    fontSize: "16px",
    color: Colour.black,
    minHeight: `${16 * LINE_HEIGHT_MULTI}px`,
    fontWeight: "450",
  },
  [LabelType.CTA]: {
    fontSize: "16px",
    color: Colour.tertiary,
    minHeight: `${16 * LINE_HEIGHT_MULTI}px`,
    fontWeight: "450",
  },
  [LabelType.PageTitle]: {
    fontSize: "26px",
    color: Colour.primary,
    minHeight: `${24 * LINE_HEIGHT_MULTI}px`,
    fontWeight: "600",
  },
  [LabelType.Banner]: {
    fontSize: "16px",
    color: Colour.white,
    minHeight: `${16 * LINE_HEIGHT_MULTI}px`,
    fontWeight: "450",
  },
};

function Label(
  props: Props = {
    type: LabelType.Primary,
    align: "left",
    children: undefined,
  },
) {
  const config = labelConfig[props.type];

  return (
    <div
      css={{
        ...config,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        textAlign: props.align,
      }}
    >
      {props.children}
    </div>
  );
}

export default Label;
