import firebase from 'firebase/compat/app'
import "firebase/compat/database"
import "firebase/compat/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBD-B0jTfW0kBtubC3hEcTpZJ6rcEwtXQk",
    authDomain: "jearni-4facf.firebaseapp.com",
    databaseURL: "https://jearni-4facf.firebaseio.com",
    projectId: "jearni-4facf",
    storageBucket: "jearni-4facf.appspot.com",
    messagingSenderId: "239611658487",
    appId: "1:239611658487:web:727d5d27a6a956b2e9d3f2",
    measurementId: "G-GVMP8Y456G"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  // if (location.hostname === "localhost") {
  //   // Point to the RTDB emulator running on localhost.
  //   //  db.useEmulator("127.0.0.1", 9000);

  //   const auth = firebase.auth();
  //   auth.useEmulator("http://127.0.0.1:9099");
  // }  

  export default firebase