import { Exercise, BodyPart } from "../../Types/Types";
import { useCallback } from "react";
import { useGetExercises } from "../../Hooks/GetExercises";
import ScrollerV from "./ScrollerV";

interface Props {
  onChange: (exercise: Exercise) => void;
  bodyPart?: BodyPart;
}

function ExerciseScroller({ onChange, bodyPart }: Props) {
  const exercises: Exercise[] = useGetExercises(bodyPart);

  const exercisesToStrings = useCallback(
    () => {
      return exercises.map(val => val.name)
    }, [exercises]
  );

  const onChangeTransformer = useCallback(
    (name: string) => {
      // transforms the string name of the exercise into the exercise object
      // strings are passed in from the Select component, but we want to output the object for storing in state
      // todo: use id instead of name because of duplicates (actually, we can just change the interface to use index instead of string)
      const exercise = exercises.find((i) => i.name === name);
      exercise && onChange(exercise);
    },
    [exercises, onChange]
  );

  return (
    <ScrollerV
      onChange={onChangeTransformer}
      exercises={exercisesToStrings()}
    />
  );
}

export default ExerciseScroller;
