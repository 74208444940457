/** @jsxImportSource @emotion/react */
import { Colour } from "../../Constants/Colour";
import BackArrow from "../../Images/BackArrow.svg";

interface Props {
  content: { onTap: () => void; title: string }[];
}

function button(content: { onTap: () => void; title: string }, length: number) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        color: Colour.secondaryBlack,
        fontSize: "16px",
        fontWeight: "600",
        textAlign: "center",
        padding: "0 24px",
        textUnderlineOffset: "5px",
      }}
      onClick={content.onTap}
    >
      <img
        css={{ marginRight: "16px" }}
        src={BackArrow}
        alt={"exercise dropdown arrow"}
      />
      {content.title}
    </div>
  );
}

function BottomNav({ content }: Props) {
  if (!content || content.length === 0) {
    return null;
  }

  return (
    <div
      css={{
        width: "100%",
        height: "57px",
        minHeight: "57px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: Colour.superLightGrey,
      }}
    >
      {content.map((_, i) => {
        return button(content[i], content.length);
      })}
    </div>
  );
}

export default BottomNav;
