/** @jsxImportSource @emotion/react */
import { ReactNode, useEffect, useRef, useState } from "react";
import PrimaryButton, {
  ButtonType,
} from "../../Components/Buttons/PrimaryButton";
import {
  BodyPart,
  Exercise,
  Workout,
  WorkoutExercise,
  Set as MySet,
  Deletable,
  WorkoutPlan,
  User,
} from "../../Types/Types";
import Scroller from "../../Components/ExerciseSelect/ScrollerH";
import Spacer, { SpacerSize } from "../../Components/Spacing/Spacer";
import Label, { LabelType } from "../../Components/Labels/Label";
import ExerciseScroller from "../../Components/ExerciseSelect/ExerciseScroller";
import BodyPartScroller from "../../Components/BodyPartSelect/BodyPartScroller";
import moment from "moment";
import firebase from "../../firebase";
import {
  EXERCISES_PATH,
  SETS_PATH,
  USERS_PATH,
  WORKOUTS_PATH,
} from "../../Constants/DatabasePaths";
import UpArrow from "../../Images/Union.svg";
import DownArrow from "../../Images/Vector.svg";
import PlusSVG from "../../Images/Plus.svg";
import TickSVG from "../../Images/Tick.svg";
import NextSVG from "../../Images/NextAction.svg";
import Gallery from "../../Components/Gallery/Gallery";
import { Colour, Gradient } from "../../Constants/Colour";
import { Modal } from "antd";
import BottomMenu from "../../Components/BottomBar/BottomMenu";
import ActionButton, {
  ActionButtonType,
} from "../../Components/Buttons/ActionButton";
import { useGetWorkoutPlans } from "../../Hooks/GetWorkoutPlans";
import BottomNav from "../../Components/BottomBar/BottonNav";
import { useGetAuth } from "../../Hooks/GetAuth";

type Props = {
  workoutPage: AppWorkoutPageType;
  setWorkoutPage: (type: AppWorkoutPageType) => void;
  exercises: WorkoutExercise[];
  setExercises: (type: WorkoutExercise[]) => void;
  startDateTime: number | undefined;
  setStartDateTime: (type: number | undefined) => void;
  exercise: Exercise | undefined;
  setExercise: (type: Exercise | undefined) => void;
  bodyPart: BodyPart;
  setBodyPart: (type: BodyPart) => void;
  workoutId: string | undefined;
  setWorkoutId: (type: string | undefined) => void;
  workouts: Workout[];
  isFollowingWorkoutPlan: boolean;
  setIsFollowingWorkoutPlan: (type: boolean) => void;
};

export enum AppWorkoutPageType {
  Home,
  SelectWorkoutPlan,
  SelectExercise,
  LogSet,
  WorkoutSummary,
}

const UNIT = "kg";

function handleDeleteExercise(
  user: User,
  workoutId?: string,
  exerciseId?: string,
) {
  console.log(workoutId, exerciseId);
  if (workoutId && exerciseId) {
    Modal.confirm({
      title: "Are you sure you want to delete this exercise?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        // Proceed with the deletion if user confirms
        firebase
          .database()
          .ref(USERS_PATH)
          .child(user.id)
          .child(WORKOUTS_PATH)
          .child(workoutId)
          .child(EXERCISES_PATH)
          .child(exerciseId)
          .remove();
      },
      onCancel() {
        // Optionally handle the cancel, or simply do nothing
      },
    });
  }
}

function handleDeleteSet(
  user: User,
  workoutId?: string,
  exerciseId?: string,
  setId?: string,
) {
  console.log(workoutId, exerciseId, setId);
  if (workoutId && exerciseId && setId) {
    Modal.confirm({
      title: "Are you sure you want to delete this set?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        // Proceed with the deletion if user confirms
        firebase
          .database()
          .ref(USERS_PATH)
          .child(user.id)
          .child(WORKOUTS_PATH)
          .child(workoutId)
          .child(EXERCISES_PATH)
          .child(exerciseId)
          .child(SETS_PATH)
          .child(setId)
          .remove();
      },
      onCancel() {
        // Optionally handle the cancel, or simply do nothing
      },
    });
  }
}

function AppWorkoutPage({
  workoutPage,
  setWorkoutPage,
  exercise,
  setExercise,
  startDateTime,
  setStartDateTime,
  exercises,
  setExercises,
  bodyPart,
  setBodyPart,
  workoutId,
  setWorkoutId,
  workouts,
}: Props) {
  // mobile page state machine

  // state for this component
  const workoutPlans: WorkoutPlan[] = useGetWorkoutPlans();
  const user = useGetAuth();
  const [workoutPlanExpandedState, setWorkoutPlanExpandedState] = useState<
    boolean[]
  >([]);
  const [repsNumber, setRepsNumber] = useState<number>(0);
  const [weightNumber, setWeightNumber] = useState<number>(0);
  const [exercisesExpandedState, setExercisesExpandedState] = useState<
    boolean[]
  >([]);
  const [runHoldTimer, setRunHoldTimer] = useState<boolean>(false);
  const timeoutId = useRef<any>(null);
  const [heldItemId, setHeldItemId] = useState<{
    itemId?: string;
    itemType: Deletable;
    childId?: string;
  }>();
  // ========================

  useEffect(() => {
    // set the expanded state for all exercise cards
    const state =
      workouts
        .find((wo) => {
          return wo.id === workoutId;
        })
        ?.exercises.map((_, index) => {
          return index === 0;
        }) || [];

    setExercisesExpandedState(state);

    return () => {};
  }, [workouts, workoutId]);

  useEffect(() => {
    // set the expanded state for all exercise cards
    const state =
      workoutPlans.map((_, index) => {
        return index === 0;
      }) || [];

    setWorkoutPlanExpandedState(state);
    return () => {};
  }, [workoutPlans]);

  // hold timer
  useEffect(() => {
    // we've started holding and there isn't a timer running
    if (runHoldTimer && timeoutId.current === null) {
      console.log("start hold");
      timeoutId.current = setTimeout(() => {
        if (runHoldTimer) {
          console.log("still holding");
          if (heldItemId?.itemType === Deletable.Exercise) {
            console.log("hello??");
            handleDeleteExercise(user, workoutId, heldItemId.itemId);
          }

          if (heldItemId?.itemType === Deletable.Set) {
            handleDeleteSet(
              user,
              workoutId,
              heldItemId.itemId,
              heldItemId.childId,
            );
          }
        }
        // clear
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }, 250);
    }

    return () => {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    };
  }, [runHoldTimer, heldItemId, workoutId, user]);

  function startWorkout(workoutPlan?: WorkoutPlan) {
    const workout: Workout = {
      durationMs: 0,
      startDateTime: moment().valueOf(),
      exercises: [],
    };

    if (workoutPlan !== undefined) {
      workout.workoutPlan = workoutPlan;
      console.log("add workout plan to workout entity!", workoutPlan, workout);
    }

    const newWorkoutId = firebase
      .database()
      .ref(USERS_PATH)
      .child(user.id)
      .child(WORKOUTS_PATH)
      .push(workout, (error: any) => {
        if (error) {
          console.error("Failed to create workout", error);
        }
        // Optionally, show a message to the user
      });
    setWorkoutId(newWorkoutId.key || undefined);
    setStartDateTime(moment().valueOf());
  }

  function onStartWorkoutClicked(workoutPlan?: WorkoutPlan) {
    startWorkout(workoutPlan);
    setWorkoutPage(AppWorkoutPageType.SelectExercise);
  }

  function endWorkout() {
    console.log("ending?");
    if (workoutId && startDateTime) {
      console.log("ending with id");
      const endTime = moment().valueOf();
      const elapsedTime = endTime - startDateTime;

      // Update the workout's duration in Firebase
      firebase
        .database()
        .ref(USERS_PATH)
        .child(user.id)
        .child(WORKOUTS_PATH)
        .child(workoutId)
        .update({ durationMs: elapsedTime })
        .catch((error: any) => {
          console.error(
            "Failed to update duration when ending workout:",
            error,
          );
          // Optionally, show a message to the user
        });

      // Reset the local state
      setStartDateTime(undefined);
      setWorkoutId(undefined);
      setExercises([]); // Clear the current exercises
      setExercise(undefined);
    }
  }

  function onEndWorkoutClicked() {
    endWorkout();
    setWorkoutPage(AppWorkoutPageType.Home);
  }

  function submitSet() {
    if (exercise && bodyPart && repsNumber && workoutId) {
      console.log("hello?");
      const set: MySet = {
        reps: repsNumber,
        weight: weightNumber,
      };

      var exerciseId: string | undefined;
      exercises.forEach((i) => {
        if (
          i.exercise.name === exercise.name &&
          i.exercise.bodyPart === exercise.bodyPart
        ) {
          console.log("already have this exercise", i.exercise, exercise);
          // have already logged a set in this exercise so don't need to create a new entity
          exerciseId = i.id;
        }
      });

      if (exerciseId === undefined) {
        // first set of this exercise, so need to create an entity
        const workoutExercise: WorkoutExercise = {
          exercise: exercise,
          sets: [set],
        };

        exerciseId =
          firebase
            .database()
            .ref(USERS_PATH)
            .child(user.id)
            .child(WORKOUTS_PATH)
            .child(workoutId)
            .child(EXERCISES_PATH)
            .push(workoutExercise).key || undefined;

        if (exerciseId) {
          console.log("has got id" + exerciseId);
          workoutExercise.id = exerciseId;
          const newArray = [...exercises, workoutExercise];
          console.log(newArray);
          setExercises(newArray);
        }
      } else {
        // has an entity, so just update the set
        console.log("other set");
        firebase
          .database()
          .ref(USERS_PATH)
          .child(user.id)
          .child(WORKOUTS_PATH)
          .child(workoutId)
          .child(EXERCISES_PATH)
          .child(exerciseId)
          .child(SETS_PATH)
          .push(set, (error: any) => {
            if (error) {
              console.error("Failed to log set:", error);
            }
          });

        // find the index of the existing exercise
        const exerciseIndex = exercises.findIndex(
          (i) => i.exercise.id === exerciseId,
        );

        // if the exercise is found, update its sets
        if (exerciseIndex !== -1) {
          const updatedExercise = {
            ...exercises[exerciseIndex],
            sets: [...exercises[exerciseIndex].sets, set],
          };

          // Replace the existing exercise in the exercises array
          const updatedExercises = [...exercises];
          updatedExercises[exerciseIndex] = updatedExercise;
          setExercises(updatedExercises);
        }
      }
    }
  }

  function renderSet(set: MySet, workoutExercise?: WorkoutExercise) {
    return (
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "60%",
          backgroundColor: Colour.superLightGrey,
          padding: "3px 11px",
          borderRadius: "10px",
          marginTop: "12px",
        }}
        onTouchStart={() => {
          setRunHoldTimer(true);
          setHeldItemId({
            itemId: workoutExercise?.id,
            itemType: Deletable.Set,
            childId: set.id,
          });
        }}
        onTouchEnd={() => {
          setRunHoldTimer(false);
          setHeldItemId(undefined);
        }}
        onTouchMove={() => {
          setRunHoldTimer(false);
          setHeldItemId(undefined);
        }}
      >
        <div css={{ width: "50%" }}>
          <Label type={LabelType.Table} align={"left"}>
            {set.reps + " x"}
          </Label>
        </div>
        <div css={{ width: "50%" }}>
          <Label type={LabelType.Table} align={"right"}>
            {set.weight + " " + UNIT}
          </Label>
        </div>
      </div>
    );
  }

  function renderSets() {
    // todo: either update useGetSets hook to only fetch the workout/exercise, or fetch from local exercise state instead to save calls.
    const ex = workouts
      .find((wo) => {
        return wo.id === workoutId;
      })
      ?.exercises.find((ex) => {
        console.log(
          "looking for on gallery",
          exercise?.name,
          exercise?.bodyPart,
        );
        return (
          ex.exercise.name === exercise?.name &&
          ex.exercise.bodyPart === exercise?.bodyPart
        );
      });

    if (!ex) {
      return;
    }

    const sets = [...ex.sets].reverse().map((set) => renderSet(set, ex));

    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Label type={LabelType.Primary} align={"center"}>
          Sets
        </Label>
        <div
          css={{
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              height: "100%",
              position: "absolute",
              overflow: "scroll",
              paddingBottom: "12px",
            }}
          >
            {sets}
            <Spacer size={SpacerSize.ActionBottom} />
          </div>
        </div>
      </div>
    );
  }

  function renderPreviousSets() {
    let sets;

    workouts.some((wo, index) => {
      if (index === 30) {
        console.log("couldnt find in last 30 workouts");
        return true;
      }
      if (wo.id === workoutId) {
        return false;
      }

      const ex = wo.exercises.find((ex) => {
        console.log(
          "looking for on prev sets",
          exercise?.name,
          exercise?.bodyPart,
        );
        return (
          ex.exercise.name === exercise?.name &&
          ex.exercise.bodyPart === exercise?.bodyPart
        );
      });

      if (ex != null) {
        sets = ex.sets.map((set) => renderSet(set));
        return true;
      }

      return false;
    });

    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Label type={LabelType.Primary} align={"center"}>
          Last time
        </Label>
        <div
          css={{
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          <div
            css={{
              position: "absolute",
              width: "90%",
              height: "12px",
              backgroundImage: Gradient.scrollTop,
              top: -1,
              zIndex: 1,
            }}
          />
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              height: "100%",
              position: "absolute",
              overflow: "scroll",
              paddingBottom: "12px",
            }}
          >
            {sets}
          </div>
          <div
            css={{
              position: "absolute",
              width: "90%",
              height: "12px",
              backgroundImage: Gradient.scrollBottom,
              bottom: -1,
              zIndex: 1,
            }}
          />
        </div>
      </div>
    );
  }

  // todo: optimise: we should keep track of PRs as values in the DB which are updated each time you submit an exercise, so we don't have to loop through every time
  // call should be: fetch weight/total PR for x exercise

  function renderPRs() {
    let setPR: ReactNode = undefined,
      totalPR: number = 0,
      weightPR: number = 0;

    workouts.some((wo, index) => {
      if (index === 30) {
        return true;
      }
      if (wo.id === workoutId) {
        return false;
      }

      const ex = wo.exercises.find((ex) => {
        return (
          ex.exercise.name === exercise?.name &&
          ex.exercise.bodyPart === exercise?.bodyPart
        );
      });

      if (ex != null) {
        ex.sets.forEach((set) => {
          if (set.weight > weightPR) {
            weightPR = set.weight;
          }
          if (set.weight * set.reps > totalPR) {
            totalPR = set.weight * set.reps;
            setPR = renderSet(set);
          }
        });
        return false;
      }
      return false;
    });

    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Label type={LabelType.Primary} align={"center"}>
          Weight
        </Label>
        <Label type={LabelType.Blackery} align={"center"}>
          {weightPR + ` ${UNIT}`}
        </Label>
        <Spacer size={SpacerSize.Large} />
        {setPR && (
          <>
            <Label type={LabelType.Primary} align={"center"}>
              Set
            </Label>
            {setPR}
          </>
        )}
      </div>
    );
  }

  function renderWorkoutPlans() {
    const theWorkouts = workoutPlans.map((workoutPlan, index) => {
      const expanded = workoutPlanExpandedState[index] === true;

      const exercises = workoutPlan.exercises.map((ex, i) => {
        const lastIndex = workoutPlan.exercises.length - 1;
        return (
          <div
            css={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: Colour.white,
              width: "100%",
              height: "37px",
              borderRadius: "10px",
              padding: "20px",
              marginBottom: i === lastIndex ? "0px" : "10px",
            }}
            key={i}
          >
            <Label type={LabelType.Blackery} align={"center"}>
              {ex.name}
            </Label>
          </div>
        );
      });

      return (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
          key={index}
        >
          {/* workout banner with name */}
          <div
            css={{
              width: "100%",
              backgroundColor: "#F0F0F0",
              height: "38px",
              borderRadius: expanded ? "10px 10px 0 0" : "10px",
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
              padding: "15px",
            }}
            onClick={() => {
              const copy = [...workoutPlanExpandedState];
              copy[index] = !copy[index];
              setWorkoutPlanExpandedState(copy);
            }}
            onTouchStart={() => {
              // setRunHoldTimer(true)
              // setHeldItemId({itemId: workout.id, itemType: Deletable.Workout})
            }}
            onTouchEnd={() => {
              // setRunHoldTimer(false)
              // setHeldItemId(undefined)
            }}
            onTouchMove={() => {
              // setRunHoldTimer(false)
              // setHeldItemId(undefined)
            }}
          >
            <div css={{ width: "55%" }}>
              <Label type={LabelType.Heading} align={"left"}>
                {workoutPlan.name}
              </Label>
            </div>
            <img
              src={expanded ? DownArrow : UpArrow}
              alt={"exercise dropdown arrow"}
              onClick={() => {
                onStartWorkoutClicked(workoutPlan);
              }}
            />
          </div>
          {/* expanded exercise details - sets */}
          {expanded && (
            <div
              css={{
                padding: "10px",
                backgroundColor: Colour.superLightGrey,
                width: "100%",
                borderRadius: "0 0 10px 10px",
              }}
            >
              {exercises}
            </div>
          )}
          <Spacer size={SpacerSize.Small} />
        </div>
      );
    });

    const summary = (
      <div
        css={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <div
          css={{
            position: "absolute",
            width: "95%",
            height: "12px",
            backgroundImage: Gradient.scrollTop,
            top: -1,
            zIndex: 1,
          }}
        />
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
            overflow: "scroll",
            padding: "20px",
          }}
        >
          {theWorkouts}
        </div>
        <div
          css={{
            position: "absolute",
            width: "95%",
            height: "12px",
            backgroundImage: Gradient.scrollBottom,
            bottom: -1,
            zIndex: 1,
          }}
        />
      </div>
    );
    return { summary };
  }

  function renderWorkoutPlanExercises() {
    const exercises = workouts.find((wo) => {
      return wo.id === workoutId;
    })?.workoutPlan?.exercises;

    const renderExercises = exercises?.map((ex, i) => {
      const lastIndex = exercises.length - 1;
      const isSelected =
        exercise?.name === ex.name && exercise?.bodyPart === ex.bodyPart;
      return (
        <div
          css={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundColor: Colour.white,
            width: "100%",
            height: "37px",
            borderRadius: "10px",
            padding: "20px",
            marginBottom: i === lastIndex ? "0px" : "10px",
            borderStyle: isSelected ? "solid" : "none",
            borderColor: isSelected ? Colour.primary : "none",
          }}
          key={i}
          onClick={() => {
            setExercise(ex);
          }}
        >
          <Label type={LabelType.Blackery} align={"center"}>
            {ex.name}
          </Label>
        </div>
      );
    });

    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          css={{
            padding: "10px",
            backgroundColor: Colour.superLightGrey,
            width: "100%",
            borderRadius: "0 0 10px 10px",
          }}
        >
          {renderExercises}
        </div>
      </div>
    );
  }

  function renderWorkoutSummary() {
    // todo: either update useGetSets hook to only fetch the workout/exercise, or fetch from local exercise state instead to save calls.
    // fetch this in useeffect and add to state, then can store 'expanded' state here to control exercises

    let numExercises = 0;
    let numSets = 0;
    let totalWeight = 0;
    let date = "";
    let duration = "";

    const theWorkout = workouts.find((wo) => {
      return wo.id === workoutId;
    });

    numExercises = theWorkout?.exercises.length || 0;

    if (theWorkout) {
      date = moment(theWorkout.startDateTime).format("Do MMM YY");

      const diff = moment.duration(theWorkout.durationMs, "milliseconds");
      const seconds = diff.seconds();
      const minutes = diff.minutes();
      const hours = diff.hours();

      const minutesString = minutes === 0 ? "" : `${minutes}m `;
      const hoursString = hours === 0 ? "" : `${hours}hr `;
      duration = hoursString + minutesString + seconds + "s";
    }

    const summary = workouts
      .find((wo) => {
        return wo.id === workoutId;
      })
      ?.exercises.map((ex, index) => {
        // for each set
        const sets = ex.sets.map((set) => {
          totalWeight += set.weight;
          return renderSet(set, ex);
        });

        // for each exercise
        const exerciseString = ex.sets.length + "x " + ex.exercise.name;
        const expanded = exercisesExpandedState[index] === true;
        numSets += ex.sets.length;
        return (
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            {/* exercise banner with name */}
            <div
              css={{
                width: "100%",
                backgroundColor: Colour.superLightGrey,
                height: "38px",
                borderRadius: "3px",
                alignItems: "center",
                justifyContent: "space-between",
                display: "flex",
                padding: "15px",
              }}
              onClick={() => {
                const copy = [...exercisesExpandedState];
                copy[index] = !copy[index];
                setExercisesExpandedState(copy);
              }}
              onTouchStart={() => {
                setRunHoldTimer(true);
                setHeldItemId({ itemId: ex.id, itemType: Deletable.Exercise });
              }}
              onTouchEnd={() => {
                setRunHoldTimer(false);
                setHeldItemId(undefined);
              }}
              onTouchMove={() => {
                setRunHoldTimer(false);
                setHeldItemId(undefined);
              }}
            >
              <Label type={LabelType.Heading} align={"left"}>
                {exerciseString}
              </Label>
              <img
                src={expanded ? DownArrow : UpArrow}
                alt={"exercise dropdown arrow"}
              />
            </div>
            {/* expanded exercise details - sets */}
            {expanded && <Spacer size={SpacerSize.Small} />}
            {expanded && sets}
            {expanded && (
              <div
                onClick={() => {
                  setExercise(ex.exercise);
                  setWorkoutPage(AppWorkoutPageType.LogSet);
                }}
                css={{ width: "50%", padding: "10px" }}
              >
                <Label type={LabelType.CTA} align={"center"}>
                  Add
                </Label>
              </div>
            )}
            <Spacer size={SpacerSize.Small} />
          </div>
        );
      });

    return { summary, numExercises, numSets, totalWeight, date, duration };
  }

  let blah;

  const workout = workouts.find((wo) => {
    return wo.id === workoutId;
  });

  const planExercises = workouts.find((wo) => {
    return wo.id === workoutId;
  })?.workoutPlan?.exercises;

  console.log(planExercises);

  switch (workoutPage) {
    case AppWorkoutPageType.Home:
      blah = (
        <div
          css={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <PrimaryButton
            buttonType={ButtonType.Primary}
            onClick={() => {
              onStartWorkoutClicked(undefined);
            }}
          >
            Freestyle
          </PrimaryButton>
          <Spacer size={SpacerSize.Medium} />
          <PrimaryButton
            buttonType={ButtonType.Primary}
            onClick={() => {
              setWorkoutPage(AppWorkoutPageType.SelectWorkoutPlan);
            }}
          >
            Follow a plan
          </PrimaryButton>
        </div>
      );
      break;
    case AppWorkoutPageType.SelectWorkoutPlan:
      const summary = renderWorkoutPlans();
      blah = (
        <div
          css={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {summary.summary}
        </div>
      );
      break;
    case AppWorkoutPageType.SelectExercise:
      blah = (
        <div
          css={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <div
            css={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            {workout?.workoutPlan === undefined ? (
              <>
                <Label type={LabelType.Primary} align={"center"}>
                  Muscle Group
                </Label>
                <Spacer size={SpacerSize.Small} />
                <BodyPartScroller onChange={setBodyPart} />
                <Spacer size={SpacerSize.Large} />
                <Label type={LabelType.Primary} align={"center"}>
                  Exercise
                </Label>
                <Spacer size={SpacerSize.Small} />
                <ExerciseScroller onChange={setExercise} bodyPart={bodyPart} />
              </>
            ) : (
              renderWorkoutPlanExercises()
            )}
          </div>
          {planExercises && (
            <ActionButton
              buttonType={ActionButtonType.Primary}
              onClick={() => {
                // find index of selected exercise
                const index = planExercises.findIndex(
                  (i) =>
                    i.name === exercise?.name &&
                    i.bodyPart === exercise?.bodyPart,
                );

                // select next exercise in list
                if (index !== -1 && index < planExercises.length - 1) {
                  setExercise(planExercises[index + 1]);
                }
              }}
            >
              <img src={NextSVG} alt={"action button plus icon"} />
            </ActionButton>
          )}
        </div>
      );
      break;
    case AppWorkoutPageType.LogSet:
      blah = (
        <div
          css={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          <div
            css={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            <Label type={LabelType.Primary} align={"center"}>
              Reps
            </Label>
            <Spacer size={SpacerSize.Small} />
            <Scroller
              onChange={setRepsNumber}
              bodyPart={BodyPart.Abs}
              max={99}
            />
            <Spacer size={SpacerSize.Large} />
            <Label type={LabelType.Primary} align={"center"}>
              Weight
            </Label>
            <Spacer size={SpacerSize.Small} />
            <Scroller
              onChange={setWeightNumber}
              bodyPart={BodyPart.Abs}
              max={99}
            />
            <Spacer size={SpacerSize.Large} />
            <Gallery
              content={[
                { content: renderPRs(), title: "PR" },
                { content: renderPreviousSets(), title: "History" },
                { content: renderSets(), title: "Sets" },
              ]}
            />
          </div>
          {/* {planExercises && (
            <ActionButton
              buttonType={ActionButtonType.Primary}
              onClick={() => {
                // find index of selected exercise
                const index = planExercises.findIndex(
                  (i) =>
                    i.name === exercise?.name &&
                    i.bodyPart === exercise?.bodyPart,
                );

                // select next exercise in list
                if (index !== -1 && index < planExercises.length - 1) {
                  setExercise(planExercises[index + 1]);
                }
              }}
            >
              <img src={NextSVG} alt={"action button plus icon"} />
            </ActionButton>
          )} */}
          <ActionButton
            buttonType={ActionButtonType.Primary}
            onClick={submitSet}
          >
            <img src={PlusSVG} alt={"action button plus icon"} />
          </ActionButton>
        </div>
      );
      break;
    case AppWorkoutPageType.WorkoutSummary:
      const workoutSummary = renderWorkoutSummary();
      blah = (
        <div
          css={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <div
            css={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              css={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div css={{ width: "33%" }}>
                <Label type={LabelType.Primary} align={"center"}>
                  Exercises
                </Label>
                <Label type={LabelType.Blackery} align={"center"}>
                  {workoutSummary.numExercises}
                </Label>
              </div>
              <div css={{ width: "33%" }}>
                <Label type={LabelType.Primary} align={"center"}>
                  Sets
                </Label>
                <Label type={LabelType.Blackery} align={"center"}>
                  {workoutSummary.numSets}
                </Label>
              </div>
              <div css={{ width: "33%" }}>
                <Label type={LabelType.Primary} align={"center"}>
                  Weight
                </Label>
                <Label type={LabelType.Blackery} align={"center"}>
                  {workoutSummary.totalWeight}
                </Label>
              </div>
            </div>
            <Spacer size={SpacerSize.Large} />
            {workoutSummary.summary}
          </div>
          <ActionButton
            buttonType={ActionButtonType.Primary}
            onClick={onEndWorkoutClicked}
          >
            <img src={TickSVG} alt={"action button tick icon"} />
          </ActionButton>
        </div>
      );
      break;
  }

  return (
    <div
      css={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        css={{
          width: "100%",
          height: "100%",
          padding: "24px 24px 0 24px",
          display: "flex",
        }}
      >
        {blah}
      </div>
      <BottomNav
        content={
          workoutPage === AppWorkoutPageType.SelectWorkoutPlan
            ? [
                {
                  onTap: () => {
                    setWorkoutPage(AppWorkoutPageType.Home);
                  },
                  title: "Home",
                },
              ]
            : []
        }
      />
      {workoutId && (
        <BottomMenu
          content={[
            {
              onTap: () => {
                setWorkoutPage(AppWorkoutPageType.SelectExercise);
              },
              title: "Exercise",
              selected: workoutPage === AppWorkoutPageType.SelectExercise,
            },
            {
              onTap: () => {
                exercise &&
                  bodyPart &&
                  setWorkoutPage(AppWorkoutPageType.LogSet);
              },
              title: "Sets",
              selected: workoutPage === AppWorkoutPageType.LogSet,
            },
            {
              onTap: () => {
                setWorkoutPage(AppWorkoutPageType.WorkoutSummary);
              },
              title: "Summary",
              selected: workoutPage === AppWorkoutPageType.WorkoutSummary,
            },
          ]}
        />
      )}
    </div>
  );
}

export default AppWorkoutPage;
