/** @jsxImportSource @emotion/react */

interface Props {
  size: SpacerSize;
}

export enum SpacerSize {
  ActionBottom,
  XLarge,
  Large,
  Medium,
  Small,
  XSmall,
}

interface SpacerProperties {
  minHeight: string;
}

const spacerConfig: Record<SpacerSize, SpacerProperties> = {
  [SpacerSize.XLarge]: { minHeight: "48px" },
  [SpacerSize.Large]: { minHeight: "32px" },
  [SpacerSize.Medium]: { minHeight: "24px" },
  [SpacerSize.Small]: { minHeight: "16px" },
  [SpacerSize.XSmall]: { minHeight: "12px" },
  [SpacerSize.ActionBottom]: { minHeight: "80px" },
};

function Spacer(props: Props) {
  const config = spacerConfig[props.size];

  return (
    <div
      css={{
        ...config,
        width: "100%",
      }}
    ></div>
  );
}

export default Spacer;
