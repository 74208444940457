import { Select } from "antd";
import {BodyPart} from "../../Types/Types";

const bodyPartsConfig = [
    BodyPart.Abs,
  BodyPart.Back,
  BodyPart.Biceps,
  BodyPart.Cardio,
  BodyPart.Chest,
  BodyPart.Legs,
  BodyPart.Shoulders,
    BodyPart.Triceps,
];

interface Props {
  defaultValue?: BodyPart;
  value: BodyPart;
  onChange: (bodyPart: BodyPart) => void;
}

function BodyPartSelect(props: Props) {
  return (
    <Select
      defaultValue={props.defaultValue || bodyPartsConfig[0]}
      value={props.value}
      onChange={props.onChange}
    >
      {bodyPartsConfig.map((bodyPart) => (
        <Select.Option key={bodyPart} value={bodyPart}>
          {bodyPart}
        </Select.Option>
      ))}
    </Select>
  );
}

export default BodyPartSelect;
