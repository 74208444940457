/** @jsxImportSource @emotion/react */
import { Colour } from "../../Constants/Colour";

interface Props {
  content: { onTap: () => void; title: string; selected: boolean }[];
}

function button(
  content: { onTap: () => void; title: string; selected: boolean },
  length: number,
) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: Colour.secondaryBlack,
        fontSize: "16px",
        fontWeight: "600",
        textAlign: "center",
        padding: "0 15px",
        minWidth: `${100 / length}%`,
        textDecoration: content.selected ? "underline" : undefined,
        textUnderlineOffset: "5px",
      }}
      onClick={content.onTap}
    >
      {content.title}
    </div>
  );
}

function BottomMenu({ content }: Props) {
  if (!content || content.length === 0) {
    return null;
  }

  return (
    <div
      css={{
        width: "100%",
        height: "57px",
        minHeight: "57px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colour.superLightGrey,
        borderTop: `2px solid ${Colour.lightGrey}`,
      }}
    >
      <div
        css={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 19px",
        }}
      >
        {content.map((_, i) => {
          return button(content[i], content.length);
        })}
      </div>
    </div>
  );
}

export default BottomMenu;
