/** @jsxImportSource @emotion/react */
import { ReactNode } from "react";
import { Colour, Gradient } from "../../Constants/Colour";

interface Props {
  buttonType: ActionButtonType;
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

export enum ActionButtonType {
  Primary,
}

interface ButtonProperties {
  backgroundImage: string;
  backgroundColor: string;
  color: string;
}

const buttonConfig: Record<ActionButtonType, ButtonProperties> = {
  [ActionButtonType.Primary]: {
    backgroundImage: Gradient.primary,
    backgroundColor: "",
    color: Colour.white,
  },
};

function ActionButton({ onClick, disabled, children, buttonType }: Props) {
  const config = buttonConfig[buttonType];

  return (
    <div
      css={{
        display: "flex",
        justifyContent: "flex-end",
        right: 12,
        marginBottom: 12,
        position: "absolute",
        zIndex: 2,
      }}
    >
      <button
        css={{
          ...config,
          minHeight: "75px",
          minWidth: "75px",
          width: "75px",
          height: "75px",
          borderRadius: "75px",
          border: 0,
          boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.125)",
        }}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  );
}

export default ActionButton;
