import React, { useState } from "react";
import BodyPartSelect from "../../Components/BodyPartSelect/BodyPartSelect";
import ExerciseItem from "../../Components/ExerciseItem/ExerciseItem";
import firebase from "../../firebase";
import style from "./ExercisesPage.module.css";
import { Input } from "antd";
import { BodyPart, Exercise } from "../../Types/Types";
import { EXERCISES_PATH, USERS_PATH } from "../../Constants/DatabasePaths";
import { useGetExercises } from "../../Hooks/GetExercises";
import { useGetAuth } from "../../Hooks/GetAuth";

interface Props {}

function ExercisesPage(props: Props) {
  const exercises: Exercise[] = useGetExercises();
  const user = useGetAuth();
  const [inputText, setInputText] = useState<string>("");
  const [bodyPart, setBodyPart] = useState<BodyPart>(BodyPart.Abs);

  function submitExercise() {
    const exercise: Exercise = { bodyPart: bodyPart, name: inputText };

    firebase.database().ref(USERS_PATH).child(user.id).child(EXERCISES_PATH).push(exercise);
    setInputText("");
  }

  function updateInput(e: React.ChangeEvent<HTMLInputElement>) {
    setInputText(e.target.value);
  }

  function renderExercises() {
    return exercises.map((exercise) => <ExerciseItem exercise={exercise} />);
  }

  return (
    <div>
      <h1>Exercises</h1>
      <h2>New Exercise</h2>
      <Input
        onChange={updateInput}
        value={inputText}
        placeholder={"Exercise name"}
      />
      <BodyPartSelect value={bodyPart} onChange={setBodyPart} />
      <button onClick={submitExercise}>Submit</button>
      <div className={style.messagesContainer}>
        Exercises:
        {renderExercises()}
      </div>
    </div>
  );
}

export default ExercisesPage;
