import { useEffect, useRef } from "react";

export const useAnimationFrame = (
  nextAnimationFrameHandler: (deltaTimeMs: number, progress: number, deltaEase: number) => void,
	duration = Number.POSITIVE_INFINITY,
	shouldAnimate = true,
	scrollSpeed: number,
  ) => {
	const frame = useRef(0);
	// keep track of when animation is started
	const firstFrameTime = useRef(performance.now());
	const lastTime = useRef<number>(performance.now());
	const lastEase = useRef<number>(0);

	const animate = (timestamp: number) => {
		// calculate at what time fraction we are currently of whole time of animation
		let timeFraction = (timestamp - firstFrameTime.current) / duration;
		if (timeFraction > 1) {
			timeFraction = 1;
		}

		const deltaTime = timestamp - lastTime.current
		const ease = easeOutSine(timeFraction) * (scrollSpeed / 2)
		const deltaEase = ease - lastEase.current

		if (timeFraction <= 1) {
			nextAnimationFrameHandler(deltaTime, timeFraction, deltaEase);
			lastTime.current = timestamp
			lastEase.current = ease

			// request next frame only in cases when we not reached 100% of duration
			if (timeFraction !== 1) frame.current = requestAnimationFrame(animate);
		}
	};

	useEffect(() => {
		if (shouldAnimate) {
			firstFrameTime.current = performance.now();
			lastTime.current = performance.now();
			lastEase.current = 0;
			frame.current = requestAnimationFrame(animate);
		} else {
			cancelAnimationFrame(frame.current);
		}

		return () => cancelAnimationFrame(frame.current);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldAnimate, scrollSpeed]);
};

function easeOutSine(x: number): number {
	return Math.sin((x * Math.PI) / 2);
}

// function easeOutExpo(x: number): number {
// 	return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
// }

// function easeOutElastic(x: number): number {
// const c4 = (2 * Math.PI) / 3;

// 	return x === 0
// 	? 0
// 	: x === 1
// 	? 1
// 	: Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
// }

// function easeOutBack(x: number): number {
// 	const c1 = 1.70158;
// 	const c3 = c1 + 1;
	
// 	return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
// }

// function easeOutQuad(x: number): number {
// 	return 1 - (1 - x) * (1 - x);
// }

// function easeOutCirc(x: number): number {
// 	return Math.sqrt(1 - Math.pow(x - 1, 2));
// }