import style from "./App.module.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// import Header from "./Components/Header/Header";
import ExercisesPage from "./Pages/ExercisesPage/ExercisesPage";
import WorkoutsPage from "./Pages/WorkoutsPage/WorkoutsPage";
import LogBookPage from "./Pages/LogBookPage/LogBookPage";
import AnalyticsPage from "./Pages/AnalyticsPage/AnalyticsPage";
import AppPage from "./Pages/AppPage/AppPage";
import "antd/dist/antd.css";

function App() {
  return (
    <div className={style.root}>
      <BrowserRouter>
        {/* <Header /> */}
        <Switch>
          <Route exact path="/">
            <div>
              <h1>Property of: Jearni Ltd</h1>
              <h1>Address: Ridgmont, Ladysmith Road, St Albans, AL3 5QA</h1>
            </div>
          </Route>
          <Route path="/logbook">
            <LogBookPage />
          </Route>
          <Route path="/exercises">
            <ExercisesPage />
          </Route>
          <Route path="/workouts">
            <WorkoutsPage />
          </Route>
          <Route path="/analytics">
            <AnalyticsPage />
          </Route>
          <Route path="/app">
            <AppPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
