/** @jsxImportSource @emotion/react */

import { ReactNode, useEffect, useState } from "react";
import GalleryButton, { ButtonType } from "../Buttons/GalleryButton";

interface Props {
  content: { content: ReactNode; title: string }[];
}

function Gallery({ content }: Props) {
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    setIndex(0);
  }, [content.length]);

  if (!content || content.length === 0) {
    return null;
  }

  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        paddingTop: "55px",
      }}
    >
      {/* state circles */}
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          minHeight: "55px",
          position: "absolute",
          overflow: "scroll",
          left: 0,
          top: 0,
          "::-webkit-scrollbar ": {
            display: "none",
          },
        }}
      >
        <div css={{ flexGrow: 1 }} />
        {content.map((_, i) => {
          return (
            <GalleryButton
              buttonType={index === i ? ButtonType.Selected : ButtonType.Normal}
              onClick={() => {
                setIndex(i);
              }}
            >
              {content[i].title}
            </GalleryButton>
          );
        })}
        <div css={{ flexGrow: 1 }} />
      </div>
      {/* content */}
      <div
        css={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "nowrap",
        }}
      >
        {index < content.length && content?.[index].content}
      </div>
    </div>
  );
}

export default Gallery;
