/** @jsxImportSource @emotion/react */
import { ReactNode } from "react";
import { Colour } from "../../Constants/Colour";

interface Props {
  buttonType: ButtonType;
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

export enum ButtonType {
  Normal,
  Selected,
}

interface ButtonProperties {
  backgroundColor: string;
  color: string;
}

const buttonConfig: Record<ButtonType, ButtonProperties> = {
  [ButtonType.Normal]: {
    backgroundColor: Colour.superLightGrey,
    color: Colour.secondaryBlack,
  },
  [ButtonType.Selected]: {
    backgroundColor: Colour.superLightGrey,
    color: Colour.primary,
  },
};

function GalleryButton({ onClick, disabled, children, buttonType }: Props) {
  const config = buttonConfig[buttonType];

  return (
    <button
      css={{
        ...config,
        minHeight: "37px",
        fontSize: "16px",
        fontWeight: "600",
        padding: "0 15px",
        borderRadius: "40px",
        borderWidth: 0,
        margin: "0 6px",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default GalleryButton;
