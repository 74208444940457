/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { BodyPart, Exercise, Set, Workout, WorkoutExercise } from "../../Types/Types";
import ExerciseSelect from "../../Components/ExerciseSelect/ExerciseSelect";
import BodyPartSelect from "../../Components/BodyPartSelect/BodyPartSelect";
import { InputNumber } from "antd";
import { EXERCISES_PATH, SETS_PATH, USERS_PATH, WORKOUTS_PATH } from "../../Constants/DatabasePaths";
import firebase from "../../firebase";
import { Button, Modal } from "antd";
import moment from "moment";
import { useGetWorkouts } from "../../Hooks/GetWorkouts";
import Table, { ColumnsType } from "antd/lib/table";
import { useGetAuth } from "../../Hooks/GetAuth";

interface Props {}

function LogBookPage(props: Props) {
  const user = useGetAuth();
  const workouts: Workout[] = useGetWorkouts();
  const [exercise, setExercise] = useState<Exercise | undefined>();
  const [exercises, setExercises] = useState<WorkoutExercise[]>([]);
  const [bodyPart, setBodyPart] = useState<BodyPart>(BodyPart.Abs);
  const [repsNumber, setRepsNumber] = useState<number>(0);
  const [weightNumber, setWeightNumber] = useState<number>(0);
  const [startDateTime, setStartDateTime] = useState<number>(0);
  const [workoutId, setWorkoutId] = useState<string | undefined>();

  function startWorkout() {
    const workout: Workout = {
      durationMs: 0,
      startDateTime: moment().valueOf(),
      exercises: [],
    };

    const newWorkoutId = firebase.database().ref(USERS_PATH).child(user.id).child(WORKOUTS_PATH).push(workout, (error: any) => {
      console.error("Failed to create workout", error);
      // Optionally, show a message to the user
    });
    setWorkoutId(newWorkoutId.key || undefined);
    setStartDateTime(moment().valueOf())
  }

  function endWorkout() {
    if (workoutId) {
      const endTime = moment().valueOf();
      const elapsedTime = endTime - startDateTime;
  
      // Update the workout's duration in Firebase
      firebase
        .database()
        .ref(WORKOUTS_PATH)
        .child(workoutId)
        .update({ durationMs: elapsedTime }).catch((error: any) => {
          console.error("Failed to update duration when ending workout:", error);
          // Optionally, show a message to the user
      });
  
      // Reset the local state
      setStartDateTime(0);
      setWorkoutId(undefined);
      setExercises([]); // Clear the current exercises
    }
  }

  function handleDeleteWorkout(workoutId?: string) {
    if (workoutId) {
      Modal.confirm({
        title: 'Are you sure you want to delete this workout?',
        content: 'This action cannot be undone.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
            // Proceed with the deletion if user confirms
            firebase.database().ref(USERS_PATH).child(user.id).child(WORKOUTS_PATH).child(workoutId).remove();
        },
        onCancel() {
            // Optionally handle the cancel, or simply do nothing
        },
      });
    }
  }

  function handleDeleteExercise(workoutId?: string, exerciseId?: string) {
    if (workoutId && exerciseId) {
      Modal.confirm({
        title: 'Are you sure you want to delete this exercise?',
        content: 'This action cannot be undone.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
            // Proceed with the deletion if user confirms
            firebase.database().ref(USERS_PATH).child(user.id).child(WORKOUTS_PATH).child(workoutId).child(EXERCISES_PATH).child(exerciseId).remove();

            // Update the local state
            const updatedExercises = exercises.filter(exercise => exercise.id !== exerciseId);
            setExercises(updatedExercises);
        },
        onCancel() {
            // Optionally handle the cancel, or simply do nothing
        },
      });
    }
  }

  function submitSet() {
    if (exercise && bodyPart && repsNumber && workoutId) {
      const set: Set = {
        reps: repsNumber,
        weight: weightNumber,
      };


      var exerciseId: string | undefined;
      exercises.forEach(i => {
        if (i.exercise.name === exercise.name) {
          // have already logged an set in this exercise so don't need to create a new entity
          exerciseId = i.id;
        }
      })

      if (exerciseId === undefined) {
        // first set of this exercise, so need to create an entity
        console.log('first set')
        const workoutExercise: WorkoutExercise = {
          exercise: exercise,
          sets: [set],
        }

        exerciseId = firebase.database().ref(USERS_PATH).child(user.id).child(WORKOUTS_PATH).child(workoutId).child(EXERCISES_PATH).push(workoutExercise).key || undefined;

        if (exerciseId) {
          console.log('has got id' + exerciseId)
          workoutExercise.id = exerciseId;
          const newArray = [...exercises, workoutExercise];
          console.log(newArray)
          setExercises(newArray);
        }
      } else {
        // has an entity, so just update the set
        console.log('other set')
        firebase
        .database()
        .ref(WORKOUTS_PATH)
        .child(workoutId)
        .child(EXERCISES_PATH)
        .child(exerciseId)
        .child(SETS_PATH)
        .push(set, (error: any) => {
          console.error("Failed to log set:", error);
      });

        // find the index of the existing exercise
        const exerciseIndex = exercises.findIndex(i => i.exercise.id === exerciseId);

        // if the exercise is found, update its sets
        if (exerciseIndex !== -1) {
            const updatedExercise = {
                ...exercises[exerciseIndex],
                sets: [...exercises[exerciseIndex].sets, set],
            };

            // Replace the existing exercise in the exercises array
            const updatedExercises = [...exercises];
            updatedExercises[exerciseIndex] = updatedExercise;
            setExercises(updatedExercises);
        }
      }
    }
  }

  function renderExercises(workout: Workout) {
    const workoutExercisesColumn: ColumnsType<WorkoutExercise> = [
      {
        key: "exercise",
        title: "Exercise",
        dataIndex: ["exercise", "name"],
      },
      {
        render: (_: any, workoutExercise: WorkoutExercise) => (
            <Button onClick={
              (e: { stopPropagation: () => void; }) => {
                e.stopPropagation();  // Prevent the event from propagating up to the row
                handleDeleteExercise(workout.id, workoutExercise.id);
            }}>
                X
            </Button>
        ),
      },
    ];

    return (
      <Table<WorkoutExercise>
        rowKey={(record) => record.id || "key"}
        columns={workoutExercisesColumn}
        dataSource={workout.exercises}
        expandable={{ expandedRowRender: renderSets, expandRowByClick: true }}
        pagination={false}
      />
    );
  }

  function renderSets(exercise: WorkoutExercise) {
    const setsColumns: ColumnsType<Set> = [
      {
        key: "reps",
        title: "Reps",
        dataIndex: "reps",
      },
      {
        key: "weight",
        title: "Weight",
        dataIndex: "weight",
      },
    ];

    return (
      <Table<Set>
        rowKey={(record) => record.id || "key"}
        columns={setsColumns}
        dataSource={exercise.sets}
        pagination={false}
      />
    );
  }

  const workoutColumns: ColumnsType<Workout> = [
    {
      key: "startDateTime",
      title: "Start",
      dataIndex: "startDateTime",
      render: (timestamp: number) => moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      key: "durationMs",
      title: "Duration (seconds)",
      dataIndex: "durationMs",
      render: (durationMs: number, workout: Workout) => 
        workout.id === workoutId ? "In Progress" : moment.utc(moment.duration(durationMs).asMilliseconds()).format("HH:mm:ss")
    },
    {
      render: (_, workout: Workout) => (
          <Button onClick={
            (e) => {
              e.stopPropagation();  // Prevent the event from propagating up to the row
              handleDeleteWorkout(workout.id);
          }}>
              X
          </Button>
      ),
    },
  ];

  function renderWorkouts() {
    return (
      <Table<Workout>
        rowKey={(record) => record.id || "key"}
        columns={workoutColumns}
        dataSource={workouts}
        expandable={{ expandedRowRender: renderExercises, expandRowByClick: true }}
        pagination={false}
      />
    );
  }

  return (
    <div>
      <h1
        css={{
          padding: "32px",
          backgroundColor: "hotpink",
          fontSize: "24px",
          borderRadius: "4px",
        }}
      >
        Log Book
      </h1>
      <BodyPartSelect value={bodyPart} onChange={setBodyPart} />
      <ExerciseSelect
        value={exercise}
        onChange={setExercise}
        bodyPart={bodyPart}
      />
      Reps:
      <InputNumber min={0} onStep={setRepsNumber} value={repsNumber} />
      Weight:
      <InputNumber min={0} onStep={setWeightNumber} value={weightNumber} />
      <Button onClick={submitSet}>Submit</Button>
      <Button css={{ backgroundColor: "red" }} onClick={startWorkout} disabled={!!workoutId}>
        Start Workout
      </Button>
      <Button css={{ backgroundColor: "green" }} onClick={endWorkout} disabled={!workoutId}>
        End Workout
      </Button>
      <div>
        Workouts:
        {renderWorkouts()}
      </div>
    </div>
  );
}

export default LogBookPage;
