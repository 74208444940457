/** @jsxImportSource @emotion/react */

import moment from "moment";
import { useEffect, useState } from "react";
import Label, { LabelType } from "../Labels/Label";

interface Props {
  startTime: number;
}

function Timer({ startTime }: Props) {
  const [timeSeconds, setTimeSeconds] = useState<number>(moment().valueOf());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeSeconds(moment().valueOf());
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [timeSeconds]);

  const duration = timeSeconds - startTime;
  const diff = moment.duration(duration, "milliseconds");
  const seconds = diff.seconds();
  const minutes = diff.minutes();
  const hours = diff.hours();

  const minutesString = minutes === 0 ? "" : `${minutes}m `;
  const hoursString = hours === 0 ? "" : `${hours}hr `;

  return (
    <Label type={LabelType.Banner}>
      {hoursString + minutesString + seconds + "s"}
    </Label>
  );
}

export default Timer;
