import { useEffect, useState } from "react";
import { WorkoutPlan } from "../Types/Types";
import firebase from "../firebase";
import { USERS_PATH, WORKOUT_PLANS_PATH } from "../Constants/DatabasePaths";
import { useGetAuth } from "./GetAuth";

export function useGetWorkoutPlans(): WorkoutPlan[] {
  const user = useGetAuth();
  const [workoutPlans, setWorkoutPlans] = useState<WorkoutPlan[]>([]);

  useEffect(() => {
    var workoutPlanRef = firebase
      .database()
      .ref(USERS_PATH)
      .child(user.id)
      .child(WORKOUT_PLANS_PATH);

    workoutPlanRef.on("value", (snapshot) => {
      const data: any = snapshot.val();

      const items: WorkoutPlan[] = [];

      Object.entries(data).forEach(([workoutPlanKey, workoutPlanData]) => {
        const workoutPlanVal = workoutPlanData as WorkoutPlan;
        items.push({
          id: workoutPlanKey,
          name: workoutPlanVal.name,
          exercises: workoutPlanVal.exercises,
        });
      });
      setWorkoutPlans(items);
    });

    return () => {
      workoutPlanRef.off();
    };
  }, [user.id]);

  return workoutPlans;
}
