import { Workout, Set, WorkoutExercise, Exercise, WorkoutPlan } from "../Types/Types";
import { useEffect, useState } from "react";
import firebase from "../firebase";
import { USERS_PATH, WORKOUTS_PATH } from "../Constants/DatabasePaths";
import { useGetAuth } from "./GetAuth";

export function useGetWorkouts(): Workout[] {
  const user = useGetAuth();
  const [workouts, setWorkouts] = useState<Workout[]>([]);

  useEffect(() => {
    const workoutsRef = firebase.database().ref(USERS_PATH).child(user.id).child(WORKOUTS_PATH);

    // nice
    workoutsRef.on("value", (snapshot) => {
      const fetchedWorkouts: Workout[] = [];

      snapshot.forEach((workoutSnap) => {
        const exercises: WorkoutExercise[] = [];

        const workoutExercises = workoutSnap.val().exercises || {};
        Object.entries(workoutExercises).forEach(([exerciseKey, exerciseData]) => {
          const sets: Set[] = [];
          const exerciseVal = exerciseData as { exercise: Exercise, sets: Record<string, any> };
          
          const exerciseSets = exerciseVal.sets || {};
          Object.entries(exerciseSets).forEach(([setKey, setValData]) => {
            const setVal = setValData as Set;
            sets.push({
              id: setKey,
              reps: setVal.reps,
              weight: setVal.weight,
            });
          });

          exercises.push({
            id: exerciseKey,
            exercise: exerciseVal.exercise,
            sets: sets,
          });
        });

        let workoutPlan: WorkoutPlan = workoutSnap.val().workoutPlan;

        fetchedWorkouts.push({
          id: workoutSnap.key || undefined,
          durationMs: workoutSnap.val().durationMs,
          startDateTime: workoutSnap.val().startDateTime,
          exercises: exercises,
          workoutPlan: workoutPlan,
        });
      });

      setWorkouts(fetchedWorkouts.sort((a, b) => b.startDateTime - a.startDateTime));
    });

    return () => {
      workoutsRef.off();
    };
  }, [user.id]);

  return workouts;
}
