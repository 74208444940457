/** @jsxImportSource @emotion/react */
import { Colour, Gradient } from "../../Constants/Colour";
import Label, { LabelType } from "../Labels/Label";
import Timer from "../Timer/Timer";
import Profilepic from "../../Images/profilepic.png";

interface Props {
  title?: string;
  startDateTime?: number;
  goToProfileCallback?: () => void;
}

function WorkoutBar({ title, startDateTime, goToProfileCallback }: Props) {
  return (
    <div
      css={{
        position: "relative",
      }}
    >
      <div
        css={{
          width: "100%",
          height: "35px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundImage: Gradient.primary,
          padding: "0 15px",
          minHeight: "40px",
        }}
      >
        {/* Profile circle */}
        <div
          css={{
            minWidth: "27px",
            minHeight: "27px",
            backgroundColor: Colour.white,
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "15px",
          }}
          onClick={goToProfileCallback}
        >
          <img
            css={{
              minWidth: "23px",
              width: "23px",
              height: "23px",
              minHeight: "22px",
              borderRadius: "20px",
            }}
            src={Profilepic}
            alt="profile"
          />
        </div>
        {startDateTime && (
          <div
            css={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Label type={LabelType.Banner}>{title}</Label>
            <Timer startTime={startDateTime} />
          </div>
        )}
      </div>
      <div
        css={{
          height: "10px",
          minHeight: "10px",
          width: "100%",
          backgroundImage: Gradient.primary,
        }}
      />
      <div
        css={{
          height: "10px",
          minHeight: "10px",
          width: "100%",
          backgroundColor: Colour.white,
          borderRadius: "10px 10px 0 0",
          position: "absolute",
          zIndex: 1,
          bottom: 0,
        }}
      />
    </div>
  );
}

export default WorkoutBar;
