import React from "react";
import {Exercise} from "../../Types/Types";

interface Props {
  exercise: Exercise;
}

function ExerciseItem(props: Props) {
  return <div key={props.exercise.name}>{props.exercise.name}-{props.exercise.bodyPart}</div>;
}

export default ExerciseItem;
