export const Colour = {
  primary: "#699BF7",
  secondary: "#93B9FF",
  tertiary: "#C3C3C3",
  lightGrey: "#D9D9D9",
  superLightGrey: "#F0F0F0",
  superSuperLightGrey: "#F9F9F9",
  secondaryBlack: "#909090",
  black: "#000000",
  grey: "#909090",
  white: "#FFFFFF",
};

export const Gradient = {
  primary: `linear-gradient(to right, ${Colour.primary}, ${Colour.secondary})`,
  scrollBottom:
    "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
  scrollTop:
    "linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1))",
};
