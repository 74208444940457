import { Select } from "antd";
import { Exercise, BodyPart } from "../../Types/Types";
import { useCallback, useEffect } from "react";
import { useGetExercises } from "../../Hooks/GetExercises";

interface Props {
  value?: Exercise;
  onChange: (exercise: Exercise) => void;
  bodyPart?: BodyPart;
}

function ExerciseSelect({ value, onChange, bodyPart }: Props) {
  const exercises: Exercise[] = useGetExercises(bodyPart);

  useEffect(() => {
    // select an exerice if one isn't selected
    if (!value && exercises.length) {
      onChange(exercises[0]);
    }
  }, [exercises, value, onChange]);

  useEffect(() => {
    // if body part changes, select the first available exercise
    if (exercises.length && bodyPart) {
      onChange(exercises[0]);
    }
  }, [exercises, onChange, bodyPart]);

  const onChangeTransformer = useCallback(
    (name: string) => {
      // transforms the string name of the exercise into the exercise object
      // strings are passed in from the Select component, but we want to output the object for storing in state
      const exercise = exercises.find((i) => i.name === name);
      exercise && onChange(exercise);
    },
    [exercises, onChange]
  );

  if (!value || !exercises.length) {
    // if no value is selected or exercises haven't loaded in yet, don't render the component
    return null;
  }

  return (
    <Select value={value.name} onChange={onChangeTransformer}>
      {exercises.map((exercise) => (
        <Select.Option key={exercise.name} value={exercise.name}>
          {exercise.name}
        </Select.Option>
      ))}
    </Select>
  );
}

export default ExerciseSelect;
