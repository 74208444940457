/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from "react";
import {
  Workout,
  WorkoutExercise,
  Set as MySet,
  Deletable,
  BodyPart,
  Exercise,
  User,
} from "../../Types/Types";
import Spacer, { SpacerSize } from "../../Components/Spacing/Spacer";
import Label, { LabelType } from "../../Components/Labels/Label";
import moment from "moment";
import firebase from "../../firebase";
import { Modal } from "antd";
import {
  EXERCISES_PATH,
  SETS_PATH,
  USERS_PATH,
  WORKOUTS_PATH,
} from "../../Constants/DatabasePaths";
import BottomBar from "../../Components/BottomBar/BottomBar";
import BottomNav from "../../Components/BottomBar/BottonNav";
import WorkoutBar from "../../Components/BottomBar/WorkoutBar";
import { Colour } from "../../Constants/Colour";
import { useGetWorkouts } from "../../Hooks/GetWorkouts";
import AppCreationPage, {
  AppCreationPageType,
} from "../AppCreationPage/AppCreationPage";
import AppWorkoutPage, {
  AppWorkoutPageType,
} from "../AppWorkoutPage/AppWorkoutPage";
import UpArrow from "../../Images/Union.svg";
import DownArrow from "../../Images/Vector.svg";
import RightArrow from "../../Images/rightArrow.svg";
import { useGetAuth } from "../../Hooks/GetAuth";
import AppAnalyticsPage, {
  AppAnalyticsPageType,
} from "../AppAnalyticsPage/AppAnalyticsPage";
import AuthButton from "../../Components/Buttons/AuthButton";

type Props = {};

// mobile pages (states)
enum MobilePage {
  Login,
  Workout,
  PreviousWorkouts,
  PreviousWorkoutSummary,
  Create,
  Analytics,
  Profile,
}

const UNIT = "kg";

function handleDeleteExercise(
  user: User,
  workoutId?: string,
  exerciseId?: string,
) {
  console.log(workoutId, exerciseId);
  if (workoutId && exerciseId) {
    Modal.confirm({
      title: "Are you sure you want to delete this exercise?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        // Proceed with the deletion if user confirms
        firebase
          .database()
          .ref(USERS_PATH)
          .child(user.id)
          .child(WORKOUTS_PATH)
          .child(workoutId)
          .child(EXERCISES_PATH)
          .child(exerciseId)
          .remove();
      },
      onCancel() {
        // Optionally handle the cancel, or simply do nothing
      },
    });
  }
}

function handleDeleteWorkout(user: User, workoutId?: string) {
  console.log("delete workout?");
  if (workoutId) {
    Modal.confirm({
      title: "Are you sure you want to delete this workout?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        // Proceed with the deletion if user confirms
        firebase
          .database()
          .ref(USERS_PATH)
          .child(user.id)
          .child(WORKOUTS_PATH)
          .child(workoutId)
          .remove();
      },
      onCancel() {
        // Optionally handle the cancel, or simply do nothing
      },
    });
  }
}

function handleDeleteSet(
  user: User,
  workoutId?: string,
  exerciseId?: string,
  setId?: string,
) {
  console.log(workoutId, exerciseId, setId);
  if (workoutId && exerciseId && setId) {
    Modal.confirm({
      title: "Are you sure you want to delete this set?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        // Proceed with the deletion if user confirms
        firebase
          .database()
          .ref(USERS_PATH)
          .child(user.id)
          .child(WORKOUTS_PATH)
          .child(workoutId)
          .child(EXERCISES_PATH)
          .child(exerciseId)
          .child(SETS_PATH)
          .child(setId)
          .remove();
      },
      onCancel() {
        // Optionally handle the cancel, or simply do nothing
      },
    });
  }
}

function AppPage(props: Props) {
  const workouts: Workout[] = useGetWorkouts();
  const user = useGetAuth();

  // state for this component
  const [mobilePage, setMobilePage] = useState<MobilePage>(MobilePage.Login);
  const [exercisesExpandedState, setExercisesExpandedState] = useState<
    boolean[]
  >([]);
  const [runHoldTimer, setRunHoldTimer] = useState<boolean>(false);
  const timeoutId = useRef<any>(null);
  const [heldItemId, setHeldItemId] = useState<{
    itemId?: string;
    itemType: Deletable;
    childId?: string;
  }>();
  const [workoutId, setWorkoutId] = useState<string | undefined>();
  // ========================

  // lifted state for workout flow
  const [workoutPage, setWorkoutPage] = useState<AppWorkoutPageType>(
    AppWorkoutPageType.Home,
  );
  const [exercises, setExercises] = useState<WorkoutExercise[]>([]);
  const [startDateTime, setStartDateTime] = useState<number | undefined>(
    undefined,
  );
  const [exercise, setExercise] = useState<Exercise | undefined>();
  const [bodyPart, setBodyPart] = useState<BodyPart>(BodyPart.Abs);
  const [inProgressWorkoutId, setInProgressWorkoutId] = useState<
    string | undefined
  >();
  const [isFollowingWorkoutPlan, setIsFollowingWorkoutPlan] =
    useState<boolean>(false);
  // =============================

  // lifted state for creation flow
  const [creationPage, setCreationPage] = useState<AppCreationPageType>(
    AppCreationPageType.Workouts,
  );
  const [workoutPlanExercises, setWorkoutPlanExercises] = useState<Exercise[]>(
    [],
  );
  // =============================

  // lifted state for analytics flow
  const [analyticsPage, setAnalyticsPage] = useState<AppAnalyticsPageType>(
    AppAnalyticsPageType.Workouts,
  );
  // =============================

  useEffect(() => {
    // set the expanded state for all exercise cards
    const state =
      workouts
        .find((wo) => {
          return wo.id === workoutId;
        })
        ?.exercises.map((_, index) => {
          return index === 0;
        }) || [];

    setExercisesExpandedState(state);

    return () => {};
  }, [workouts, workoutId]);

  useEffect(() => {
    if (user.authed) {
      setMobilePage(MobilePage.Workout);
    } else {
      setMobilePage(MobilePage.Login);
    }
  }, [user]);

  // hold timer
  useEffect(() => {
    // we've started holding and there isn't a timer running
    if (runHoldTimer && timeoutId.current === null) {
      console.log("start hold");
      timeoutId.current = setTimeout(() => {
        if (runHoldTimer) {
          console.log("still holding");
          if (heldItemId?.itemType === Deletable.Workout) {
            handleDeleteWorkout(user, heldItemId.itemId);
          }

          if (heldItemId?.itemType === Deletable.Exercise) {
            console.log("hello??");
            handleDeleteExercise(user, workoutId, heldItemId.itemId);
          }

          if (heldItemId?.itemType === Deletable.Set) {
            handleDeleteSet(
              user,
              workoutId,
              heldItemId.itemId,
              heldItemId.childId,
            );
          }
        }
        // clear
        console.log("clearing");
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }, 250);
    }

    return () => {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    };
  }, [runHoldTimer, heldItemId, workoutId, user]);

  function renderSet(set: MySet, workoutExercise?: WorkoutExercise) {
    return (
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "60%",
          backgroundColor: Colour.superLightGrey,
          padding: "3px 11px",
          borderRadius: "10px",
          marginTop: "12px",
        }}
        onTouchStart={() => {
          setRunHoldTimer(true);
          setHeldItemId({
            itemId: workoutExercise?.id,
            itemType: Deletable.Set,
            childId: set.id,
          });
        }}
        onTouchEnd={() => {
          setRunHoldTimer(false);
          setHeldItemId(undefined);
        }}
        onTouchMove={() => {
          setRunHoldTimer(false);
          setHeldItemId(undefined);
        }}
      >
        <div css={{ width: "50%" }}>
          <Label type={LabelType.Table} align={"left"}>
            {set.reps + " x"}
          </Label>
        </div>
        <div css={{ width: "50%" }}>
          <Label type={LabelType.Table} align={"right"}>
            {set.weight + " " + UNIT}
          </Label>
        </div>
      </div>
    );
  }

  function renderWorkoutSummary() {
    // todo: either update useGetSets hook to only fetch the workout/exercise, or fetch from local exercise state instead to save calls.
    // fetch this in useeffect and add to state, then can store 'expanded' state here to control exercises

    let numExercises = 0;
    let numSets = 0;
    let totalWeight = 0;
    let date = "";
    let duration = "";

    const theWorkout = workouts.find((wo) => {
      return wo.id === workoutId;
    });

    numExercises = theWorkout?.exercises.length || 0;

    if (theWorkout) {
      date = moment(theWorkout.startDateTime).format("Do MMM YY");

      const diff = moment.duration(theWorkout.durationMs, "milliseconds");
      const seconds = diff.seconds();
      const minutes = diff.minutes();
      const hours = diff.hours();

      const minutesString = minutes === 0 ? "" : `${minutes}m `;
      const hoursString = hours === 0 ? "" : `${hours}hr `;
      duration = hoursString + minutesString + seconds + "s";
    }

    const summary = workouts
      .find((wo) => {
        return wo.id === workoutId;
      })
      ?.exercises.map((ex, index) => {
        // for each set
        const sets = ex.sets.map((set) => {
          totalWeight += set.weight;
          return renderSet(set, ex);
        });

        // for each exercise
        const exerciseString = ex.sets.length + "x " + ex.exercise.name;
        const expanded = exercisesExpandedState[index] === true;
        numSets += ex.sets.length;
        return (
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            {/* exercise banner with name */}
            <div
              css={{
                width: "100%",
                backgroundColor: Colour.superLightGrey,
                height: "38px",
                borderRadius: "3px",
                alignItems: "center",
                justifyContent: "space-between",
                display: "flex",
                padding: "15px",
              }}
              onClick={() => {
                const copy = [...exercisesExpandedState];
                copy[index] = !copy[index];
                setExercisesExpandedState(copy);
              }}
              onTouchStart={() => {
                setRunHoldTimer(true);
                setHeldItemId({ itemId: ex.id, itemType: Deletable.Exercise });
              }}
              onTouchEnd={() => {
                setRunHoldTimer(false);
                setHeldItemId(undefined);
              }}
              onTouchMove={() => {
                setRunHoldTimer(false);
                setHeldItemId(undefined);
              }}
            >
              <Label type={LabelType.Heading} align={"left"}>
                {exerciseString}
              </Label>
              <img
                src={expanded ? DownArrow : UpArrow}
                alt={"exercise dropdown arrow"}
              />
            </div>
            {/* expanded exercise details - sets */}
            {expanded && <Spacer size={SpacerSize.Small} />}
            {expanded && sets}
            <Spacer size={SpacerSize.Small} />
          </div>
        );
      });

    return { summary, numExercises, numSets, totalWeight, date, duration };
  }

  function renderPreviousWorkouts() {
    const theWorkouts = workouts.map((workout) => {
      // for each exercise
      const workoutTitle = moment(workout.startDateTime).format("Do MMM YY");
      const bodyPartsSet = new Set();

      workout.exercises.forEach((ex) => {
        bodyPartsSet.add(ex.exercise.bodyPart);
      });

      let bodyPartsString = "";

      const bodyParts = Array.from(bodyPartsSet);

      bodyParts.forEach((bodyPart, index) => {
        if (index === 0) {
          bodyPartsString += bodyPart;
        } else {
          bodyPartsString += " & " + bodyPart;
        }
      });

      const workoutPlantitle = workout.workoutPlan?.name;

      return (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          {/* workout banner with name */}
          <div
            css={{
              width: "100%",
              backgroundColor: "#F0F0F0",
              height: "38px",
              borderRadius: "3px",
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
              padding: "15px",
            }}
            onClick={() => {
              setWorkoutId(workout.id);
              setMobilePage(MobilePage.PreviousWorkoutSummary);
            }}
            onTouchStart={() => {
              setRunHoldTimer(true);
              setHeldItemId({
                itemId: workout.id,
                itemType: Deletable.Workout,
              });
            }}
            onTouchEnd={() => {
              setRunHoldTimer(false);
              setHeldItemId(undefined);
            }}
            onTouchMove={() => {
              setRunHoldTimer(false);
              setHeldItemId(undefined);
            }}
          >
            <div css={{ width: "55%" }}>
              <Label type={LabelType.Heading} align={"left"}>
                {workoutPlantitle || bodyPartsString}
              </Label>
            </div>
            <div css={{ width: "40%", paddingLeft: "5%" }}>
              <Label type={LabelType.Table} align={"left"}>
                {workoutTitle}
              </Label>
            </div>
            <img src={RightArrow} alt={"exercise dropdown arrow"} />
          </div>
          <Spacer size={SpacerSize.Small} />
        </div>
      );
    });

    const summary = (
      <div
        css={{
          width: "100%",
          height: "100%",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {theWorkouts}
        </div>
      </div>
    );
    return { summary };
  }

  let blah;

  switch (mobilePage) {
    case MobilePage.Profile:
      blah = (
        <div
          css={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <div
            css={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "24px 24px 0 24px",
            }}
          >
            <div css={{ width: "100%" }}>
              <Label type={LabelType.PageTitle}>Profile</Label>
            </div>
            <Spacer size={SpacerSize.Small} />
            <AuthButton />
          </div>
        </div>
      );
      break;
    case MobilePage.Login:
      blah = (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            padding: "24px",
          }}
        >
          <AuthButton />
        </div>
      );
      break;
    case MobilePage.Workout:
      blah = (
        <AppWorkoutPage
          workoutPage={workoutPage}
          setWorkoutPage={setWorkoutPage}
          exercise={exercise}
          setExercise={setExercise}
          startDateTime={startDateTime}
          setStartDateTime={setStartDateTime}
          exercises={exercises}
          setExercises={setExercises}
          bodyPart={bodyPart}
          setBodyPart={setBodyPart}
          workoutId={inProgressWorkoutId}
          setWorkoutId={setInProgressWorkoutId}
          workouts={workouts}
          isFollowingWorkoutPlan={isFollowingWorkoutPlan}
          setIsFollowingWorkoutPlan={setIsFollowingWorkoutPlan}
        />
      );
      break;
    case MobilePage.PreviousWorkouts:
      const previousWorkouts = renderPreviousWorkouts();
      blah = (
        <div
          css={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            css={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "0 24px 0 24px",
              position: "absolute",
              overflow: "scroll",
              width: "100%",
              height: "100%",
            }}
          >
            <Spacer size={SpacerSize.Medium} />
            <div css={{ width: "100%" }}>
              <Label type={LabelType.PageTitle}>Previous Workouts</Label>
            </div>
            <Spacer size={SpacerSize.Small} />
            {previousWorkouts.summary}
          </div>
        </div>
      );
      break;
    case MobilePage.PreviousWorkoutSummary:
      const previousWorkoutSummary = renderWorkoutSummary();
      blah = (
        <div
          css={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            padding: "24px",
          }}
        >
          <div css={{ display: "flex", justifyContent: "space-between" }}>
            <Label type={LabelType.Tertiary}>
              {previousWorkoutSummary.date}
            </Label>
            <Label type={LabelType.Fourthery}>
              {previousWorkoutSummary.duration}
            </Label>
          </div>
          <div
            css={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              css={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div css={{ width: "33%" }}>
                <Label type={LabelType.Primary} align={"center"}>
                  Exercises
                </Label>
                <Label type={LabelType.Blackery} align={"center"}>
                  {previousWorkoutSummary.numExercises}
                </Label>
              </div>
              <div css={{ width: "33%" }}>
                <Label type={LabelType.Primary} align={"center"}>
                  Sets
                </Label>
                <Label type={LabelType.Blackery} align={"center"}>
                  {previousWorkoutSummary.numSets}
                </Label>
              </div>
              <div css={{ width: "33%" }}>
                <Label type={LabelType.Primary} align={"center"}>
                  Weight
                </Label>
                <Label type={LabelType.Blackery} align={"center"}>
                  {previousWorkoutSummary.totalWeight}
                </Label>
              </div>
            </div>
            <Spacer size={SpacerSize.Large} />
            {previousWorkoutSummary.summary}
          </div>
        </div>
      );
      break;
    case MobilePage.Create:
      blah = (
        <AppCreationPage
          creationPage={creationPage}
          setCreationPage={setCreationPage}
          workoutPlanExercises={workoutPlanExercises}
          setWorkoutPlanExercises={setWorkoutPlanExercises}
        />
      );
      break;
    case MobilePage.Analytics:
      blah = (
        <AppAnalyticsPage
          analyticsPage={analyticsPage}
          setAnalyticsPage={setAnalyticsPage}
          workouts={workouts}
        />
      );
      break;
  }

  return (
    <div
      css={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {mobilePage !== MobilePage.Login && (
        <WorkoutBar
          title={exercise?.name}
          startDateTime={startDateTime}
          goToProfileCallback={() => {
            setMobilePage(MobilePage.Profile);
          }}
        />
      )}
      <div css={{ width: "100%", height: "100%", display: "flex" }}>{blah}</div>
      <BottomNav
        content={
          mobilePage === MobilePage.PreviousWorkoutSummary
            ? [
                {
                  onTap: () => {
                    setMobilePage(MobilePage.PreviousWorkouts);
                  },
                  title: "Workouts",
                },
              ]
            : []
        }
      />
      {mobilePage !== MobilePage.Login && (
        <BottomBar
          content={[
            {
              onTap: () => {
                setMobilePage(MobilePage.Workout);
              },
              title: "Workout",
              selected: mobilePage === MobilePage.Workout,
            },
            {
              onTap: () => {
                setMobilePage(MobilePage.PreviousWorkouts);
              },
              title: "Log",
              selected: [
                MobilePage.PreviousWorkouts,
                MobilePage.PreviousWorkoutSummary,
              ].includes(mobilePage),
            },
            {
              onTap: () => {
                setMobilePage(MobilePage.Create);
              },
              title: "Create",
              selected: mobilePage === MobilePage.Create,
            },
            {
              onTap: () => {
                setMobilePage(MobilePage.Analytics);
              },
              title: "Analytics",
              selected: mobilePage === MobilePage.Analytics,
            },
          ]}
        />
      )}
    </div>
  );
}

export default AppPage;
