import { useEffect, useState } from "react";
import { BodyPart, Exercise } from "../Types/Types";
import firebase from "../firebase";
import { EXERCISES_PATH, USERS_PATH } from "../Constants/DatabasePaths";
import { useGetAuth } from "./GetAuth";

// Optional parameter bodyPart
// When passed, filter the exercises returned to only be that of the bodyPart
export function useGetAllExercises(
  bodyParts?: BodyPart[],
): { bodyPart: BodyPart; exercises: Exercise[] }[] | undefined {
  const user = useGetAuth();
  const [exercises, setExercises] = useState<
    { bodyPart: BodyPart; exercises: Exercise[] }[] | undefined
  >(undefined);

  useEffect(() => {
    if (bodyParts !== undefined) {
      var exercisesRef = firebase
        .database()
        .ref(USERS_PATH)
        .child(user.id)
        .child(EXERCISES_PATH);

      exercisesRef.on("value", (snapshot) => {
        const data: any = snapshot.val();

        const items: { bodyPart: BodyPart; exercises: Exercise[] }[] = [];
        for (let item in data) {
          if (bodyParts.includes(data[item].bodyPart)) {
            const bodyPart = data[item].bodyPart;
            const exercise = data[item];
            const bodyPartIndex = items.findIndex(
              (i) => i.bodyPart === bodyPart,
            );
            if (bodyPartIndex === -1) {
              items.push({ bodyPart, exercises: [exercise] });
            } else {
              items[bodyPartIndex].exercises.push(exercise);
            }
          }
        }
        setExercises(items);
      });
    }

    return () => {
      exercisesRef.off();
    };
  }, [bodyParts, user.id]);

  return exercises;
}
